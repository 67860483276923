import React from "react";
import { Box } from "@mui/material";
import { MobileCannedMessagesSearch } from "src/components/MobileCannedMessagesSearch";
import { MobileTicketsSearch } from "src/components/MobileTicketsSearch";
import { array, func, string } from "prop-types";

const MobileSearchOptions = ({
  route,
  from,
  setFrom,
  assignee,
  setAssignee,
  status,
  setStatus,
  after,
  setAfter,
  before,
  setBefore,
  department,
  setDepartment,
  createdBy,
  setCreatedBy,
  version,
  setVersion,
}) => {
  return (
    <Box
      sx={{
        width: "100vw",
        overflowX: "auto",
        whiteSpace: "nowrap",
        background: "white",
        marginTop: -2,
        py: 2,
      }}
    >
      {route.includes("/cannedMessage") ? (
        <MobileCannedMessagesSearch
          after={after}
          before={before}
          createdBy={createdBy}
          setAfter={setAfter}
          setBefore={setBefore}
          setCreatedBy={setCreatedBy}
        />
      ) : (
        <MobileTicketsSearch
          from={from}
          setFrom={setFrom}
          assignee={assignee}
          setAssignee={setAssignee}
          status={status}
          setStatus={setStatus}
          after={after}
          setAfter={setAfter}
          before={before}
          setBefore={setBefore}
          department={department}
          setDepartment={setDepartment}
          version={version}
          setVersion={setVersion}
        />
      )}
    </Box>
  );
};

MobileSearchOptions.propTypes = {
  route: string,
  from: array,
  setFrom: func,
  assignee: array,
  setAssignee: func,
  status: array,
  setStatus: func,
  after: string,
  setAfter: func,
  before: string,
  setBefore: func,
  version: string,
  setVersion: func,
  department: string,
  setDepartment: func,
  createdBy: string,
  setCreatedBy: func,
};

export { MobileSearchOptions };
