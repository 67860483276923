import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TicketHeader } from "src/components/TicketHeader";
import { TicketMessages } from "src/components/TicketMessages";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import MailLockRoundedIcon from "@mui/icons-material/MailLockRounded";
import { ReplyToTicket } from "src/components/ReplyToTicket";
import { SocketContext } from "src/context/socket";
import { useRef } from "react";
import { ticketRequest } from "src/api/ticketRequest";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "src/redux/actions/seUser";
import { Navigation } from "src/components/Navigation";
import { getTicketsList } from "src/api/getTicketsList";
import { useAtom } from "jotai";
import { defaultTicketsSearchValues, departmentAtom, ticketsAtom } from "src/components/Searchbar";
import { compareTwoObjects } from "src/utils/compareTwoObjects";
import { getParams } from "src/utils/getParams";

const Ticket = () => {
  const [ticket, setTicket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [lastMessages, setLastMessages] = useState([]);
  const [firstMessage, setFirstMessage] = useState(null);
  const [emails, setEmails] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [analystOnly, setAnalystOnly] = useState(0);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const messageRef = useRef(null);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const worker = useMemo(
    () => new Worker(new URL("src/longProcesses/ticketWorkers.js", import.meta.url)),
    []
  );
  const navigate = useNavigate();
  const [values, setValues] = useAtom(ticketsAtom);
  const { departments } = useSelector((state) => state.departments);
  const [department] = useAtom(departmentAtom);
  const [list, setList] = useState([]);

  useEffect(() => {
    document.title = "Ticket Details";
  });

  const getList = useCallback(async () => {
    if (!ticket) {
      return;
    }
    if (list.length > 0) {
      return;
    }
    if (!departments || departments.length === 0) {
      return;
    }
    let temp;
    const params = getParams(values);
    if (department !== "All") {
      temp = departments.filter(
        (dep) => dep.title.replace(/\s/g, "").toLowerCase() === department.toLowerCase()
      )[0];
    }
    const data = await getTicketsList(temp?.id ?? "all", new URLSearchParams(params));
    setList(data?.data?.list ?? []);
  }, [values, ticket, list]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    if (list.length > 0) {
      return;
    }
    if (!ticket) {
      return;
    }
    if (
      compareTwoObjects(values, defaultTicketsSearchValues, ["department", "isLoading"]) &&
      ticket.status_id === 3
    ) {
      setValues((prev) => ({
        ...prev,
        status: ["Any"],
      }));
    }
  }, [ticket, list, values, defaultTicketsSearchValues]);

  useEffect(() => {
    if (!ticket) {
      return;
    }
    socket.on("ticketChanged", (response) => {
      const temp = response.filter((element) => element.id === ticket.id)[0];
      if (temp) {
        const messages = [...temp.messages].sort((a, b) => {
          return a.ts_added - b.ts_added;
        });

        const firstMessage = messages.splice(0, 1)[0];
        const lastMessages = messages.splice(temp.messages.length - 3, 2);
        setTicket(temp);
        setMessages(messages);
        setFirstMessage(firstMessage);
        setLastMessages(lastMessages);
      }
    });

    socket.on("updateTicket", (response) => {
      const temp = response.filter((element) => element.id === ticket.id)[0];
      if (temp) {
        setTicket(temp);
      }
    });

    return () => {
      socket.off("ticketChanged");
      socket.off("updateTicket");
    };
    //eslint-disable-next-line
  }, [ticket]);

  useEffect(() => {
    socket.on("emailsChanged", (response) => {
      if (response[0].ticket_id === ticket.id) {
        setEmails(response);
      }
    });
    return () => {
      socket.off("emailsChanged");
    };
    //eslint-disable-next-line
  }, [ticket]);

  const setData = (data) => {
    setTicket(data.ticket);
    setEmails(data.emails);
    setMessages(data.messages);
    setFirstMessage(data.firstMessage);
    setLastMessages(data.lastMessages);
    setLoading(false);
  };

  useEffect(() => {
    worker.onerror = () => {
      navigate("/All");
    };
    worker.onmessage = (response) => {
      setData(response.data);
    };
  }, []);

  const getTicket = async () => {
    const key = searchParams.get("tkey");
    const id = searchParams.get("id");
    const response = await ticketRequest(id, encodeURIComponent(encodeURIComponent(key)));

    worker.postMessage({ data: response.data, deps: user.departments });
    dispatch(updateUser(response.data.allowedDepartments));
  };

  useEffect(() => {
    setLoading(true);
    getTicket();
  }, [searchParams]);

  const openReply = () => {
    setAnalystOnly(0);
    setOpen(true);
    setTimeout(() => {
      messageRef.current.focus();
      messageRef.current.setSelectionRange(message.length, message.length);
      window.scrollTo(0, document.body.scrollHeight);
    }, 50);
  };

  const openInternalMessage = () => {
    setAnalystOnly(1);
    setOpen(true);
    setTimeout(() => {
      messageRef.current.focus();
      messageRef.current.setSelectionRange(message.length, message.length);
      window.scrollTo(0, document.body.scrollHeight);
    }, 50);
  };

  const handleMessageChange = (newMessage) => {
    setMessage(newMessage);
  };

  return (
    <>
      <Box
        sx={{
          background: "white",
          marginRight: { xs: 2, md: 4 },
          marginLeft: { xs: 2, md: 4 },
          marginTop: 1,
          marginBottom: 5,
          borderRadius: 4,
          paddinY: { xs: 0.7, md: 3 },
          paddingX: { xs: 0.7, md: 1 },
          minHeight: "65vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box width="100%" display="flex" justifyContent="end" paddingBottom={1}>
          <Navigation ticket={ticket} tickets={list} />
        </Box>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              height: "65vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" size={70} />
          </Box>
        ) : (
          ticket && (
            <>
              <TicketHeader
                ticket={ticket}
                emails={emails}
                openReply={openReply}
                openInternalMessage={openInternalMessage}
              />
              <TicketMessages
                lastMessages={lastMessages}
                messages={messages}
                customer={ticket?.user}
                firstMessage={firstMessage}
              />
              <Box
                sx={{
                  width: "100%",
                  display: ticket?.status_id !== 3 ? "flex" : "none",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={openInternalMessage}
                  sx={{
                    textTransform: "none",
                    marginTop: 2,
                    width: "180px",
                    marginRight: 1,
                    borderRadius: 4,
                    color: "#9e8410",
                    borderColor: "#9e8410",
                    "&:hover": {
                      borderColor: "#5c4c08",
                    },
                    display: open ? "none" : "flex",
                    alignItems: "center",
                  }}
                >
                  <MailLockRoundedIcon />
                  <Typography sx={{ marginLeft: 0.5, marginTop: 0.2 }} variant="p">
                    Internal Message
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  onClick={openReply}
                  sx={{
                    textTransform: "none",
                    marginTop: 2,
                    width: "100px",
                    marginRight: 1,
                    borderRadius: 4,
                    color: "#5c5c5c",
                    borderColor: "#5c5c5c",
                    "&:hover": {
                      borderColor: "#474747",
                    },
                    display: open ? "none" : "flex",
                    alignItems: "center",
                  }}
                >
                  <ReplyRoundedIcon />
                  <Typography sx={{ marginLeft: 0.5, marginTop: 0.2 }} variant="p">
                    Reply
                  </Typography>
                </Button>
              </Box>

              {open && (
                <ReplyToTicket
                  setOpen={() => setOpen(false)}
                  ticket={ticket}
                  analyst_only={analystOnly}
                  openReply={openReply}
                  openInternalMessage={openInternalMessage}
                  message={message}
                  setMessage={handleMessageChange}
                  messageRef={messageRef}
                />
              )}
              <Box width="100%" display="flex" justifyContent="end" paddingTop={1}>
                <Navigation ticket={ticket} tickets={list} />
              </Box>
            </>
          )
        )}
      </Box>
    </>
  );
};

export { Ticket };
