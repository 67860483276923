import { array, func, object } from "prop-types";
import React, { useMemo } from "react";
import { DesktopHeader } from "src/components/TicketHeader/DesktopHeader";
import { MobileHeader } from "src/components/TicketHeader/MobileHeader";
import { instances } from "src/helpers/instances";

const TicketHeader = ({ ticket, emails, openReply, openInternalMessage }) => {
  const showVersion = useMemo(() => {
    return instances[window.location.origin] === "lv";
  }, [instances]);

  return (
    <>
      <DesktopHeader
        ticket={ticket}
        emails={emails}
        openReply={openReply}
        openInternalMessage={openInternalMessage}
        showVersion={showVersion}
      />
      <MobileHeader
        ticket={ticket}
        emails={emails}
        openReply={openReply}
        openInternalMessage={openInternalMessage}
        showVersion={showVersion}
      />
    </>
  );
};

TicketHeader.propTypes = {
  ticket: object,
  emails: array,
  openReply: func,
  openInternalMessage: func,
};

export { TicketHeader };
