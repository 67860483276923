import axiosInstance from "src/service/axios/axiosInstance";

export const downloadFileRequest = async (file) => {
  try {
    return await axiosInstance.get(
      `/files/download/${file.id}/${file.attached_file_name}/${file.key}`,
      {
        responseType: "blob",
      }
    );
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
