/* eslint-disable react/react-in-jsx-scope */
import { closeSnackbar, useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg) {
    this.toast(msg, "success");
  },
  warning(msg) {
    this.toast(msg, "warning");
  },
  info(msg) {
    this.toast(msg, "info");
  },
  error(msg) {
    this.toast(msg, "error");
  },
  toast(msg, variant = "default") {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      action: (snackbarId) => {
        return (
          <>
            <IconButton
              onClick={() => {
                closeSnackbar(snackbarId);
              }}
            >
              <Close htmlColor="#fff" />
            </IconButton>
          </>
        );
      },
    });
  },
};
