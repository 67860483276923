import { ADD_TICKET, SET_TICKETS, UPDATE_TICKETS } from "src/redux/actionTypes/ticketsTypes";

export const setTickets = (tickets) => (dispatch) => {
  dispatch({
    type: SET_TICKETS,
    payload: tickets,
  });
};

export const updateTickets = (tickets) => (dispatch) => {
  dispatch({
    type: UPDATE_TICKETS,
    payload: tickets,
  });
};

export const addTicket = (ticket) => (dispatch) => {
  dispatch({
    type: ADD_TICKET,
    payload: ticket,
  });
};
