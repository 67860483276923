import { useCallback, useRef, useState } from "react";

const useLongPress = (onLongPress, onClick, { shouldPreventDefault = true, delay = 200 } = {}) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const scrolltimeout = useRef();
  const target = useRef();
  const blockClick = useRef(false);

  const start = useCallback(
    (event) => {
      let blockLongPress = false;
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
          passive: false,
        });
        target.current = event.target;
        window.ontouchstart = () => {
          clearTimeout(scrolltimeout.current);
        };
        window.ontouchmove = () => {
          blockClick.current = true;
          if (!longPressTriggered) {
            blockLongPress = true;
          }
        };
        window.ontouchend = () => {
          scrolltimeout.current = setTimeout(() => {
            blockClick.current = false;
            blockLongPress = false;
          }, 200);
        };
      }
      timeout.current = setTimeout(() => {
        if (!blockLongPress) {
          onLongPress(event);
        }
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault, longPressTriggered]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && !blockClick.current && onClick();
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
    onTouchMove: () => {
      return;
    },
  };
};

const isTouchEvent = (event) => {
  return "touches" in event;
};

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export default useLongPress;
