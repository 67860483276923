import { stringToColor } from "src/utils/stringToColor";

function stringSmallAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 25,
      height: 25,
      fontSize: 13,
      pointerEvents: { xs: "none", md: "auto" },
    },
    children: name.split(" ")[1]
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name.split(" ")[0][0]}`,
  };
}

export { stringSmallAvatar };
