import { Chip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const StyledChip = ({ label, onDelete }) => {
  return (
    <Chip
      sx={{ background: "#fcfcfc", border: "1px solid #3b3b3b", ml: 1, mt: 0.5 }}
      label={label}
      onDelete={onDelete}
    />
  );
};

StyledChip.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func,
};
