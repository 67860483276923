import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { changePasswordRequest } from "src/api/changePasswordRequest";
import { InputBase } from "src/components/InputBase";
import snackbarUtils from "../../utils/SnackbarUtils";

const ChangePassword = () => {
  useEffect(() => {
    document.title = "Change Password";
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.oldPassword.value.length < 6) {
      snackbarUtils.error("Old password length must be at least 6 characters long!");
      return;
    }
    if (e.target.newPassword.value.length < 6) {
      snackbarUtils.error("New password length must be at least 6 characters long!");
      return;
    }

    if (e.target.newPasswordConfirm.value !== e.target.newPassword.value) {
      snackbarUtils.error("New passwords must match!");
      return;
    }

    const data = {
      password: e.target.oldPassword.value,
      newPassword: e.target.newPassword.value,
    };

    const response = await changePasswordRequest(data);

    if (response.status === 204) {
      e.target.oldPassword.value = "";
      e.target.newPassword.value = "";
      e.target.newPasswordConfirm.value = "";
      snackbarUtils.success("Password changed successfully!");
    }
  };

  return (
    <InputBase>
      <Box
        sx={{
          margin: "35px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "40px",
        }}
      >
        <Typography variant="h5">Change Password</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            InputLabelProps={{ style: { color: "black" } }}
            sx={{
              marginTop: 3,
              width: "100%",
              "& ::placeholder": { opacity: 0.9 },
            }}
            required
            id="oldPassword"
            label="Old password"
            placeholder="Enter your old password"
            type="password"
          />
          <TextField
            InputLabelProps={{ style: { color: "black" } }}
            sx={{
              marginTop: 3,
              width: "100%",
              "& ::placeholder": { opacity: 0.9 },
            }}
            required
            id="newPassword"
            label="New password"
            placeholder="Enter new password"
            type="password"
          />
          <TextField
            InputLabelProps={{ style: { color: "black" } }}
            sx={{
              marginTop: 3,
              width: "100%",
              "& ::placeholder": { opacity: 0.9 },
            }}
            required
            id="newPasswordConfirm"
            label="Confirm new password"
            placeholder="Enter new password again"
            type="password"
          />
          <Button
            variant="contained"
            sx={{
              width: "100%",
              marginTop: 5,
              height: 50,
              fontSize: 18,
              background: "#267aad",
            }}
            type="submit"
          >
            Change
          </Button>
        </form>
      </Box>
    </InputBase>
  );
};

export { ChangePassword };
