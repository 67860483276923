import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import MailLockRoundedIcon from "@mui/icons-material/MailLockRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TicketStatusChange } from "src/components/TicketStatusChange";
import { TicketPriorityChange } from "src/components/TicketPriorityChange";
import { TicketDepartmentChange } from "src/components/TicketDepartmentChange";
import { TicketOperatorsChange } from "src/components/TicketOperatorsChange";
import { useSelector } from "react-redux";
import { EditTimeLog } from "src/components/EditTimeLog";
import { ViewCompleteLog } from "src/components/ViewCompleteLog";
import { Reminders } from "src/components/Reminders";
import { ActivityHistory } from "src/components/ActivityHistory";
import { EmailPopup } from "src/components/EmailPopup";
import { removeEmailRequest } from "src/api/removeEmailRequest";
import { AddEmail } from "src/components/AddEmail";
import { array, bool, func, object } from "prop-types";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { ticketsAtom } from "src/components/Searchbar";
import { VersionBadge } from "src/components/VersionBadge";

const DesktopHeader = ({ ticket, emails, openReply, openInternalMessage, showVersion }) => {
  const [open, setOpen] = useState(false);
  const [openEmailOptions, setOpenEmailOptions] = useState(-1);
  const [assignees, setAssignees] = useState([]);
  const [myTime, setMyTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [keepOpen, setKeepOpen] = useState(false);
  const [keepNoEmailsOpen, setKeepNoEmailsOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const emailRef = useRef(null);
  const navigate = useNavigate();
  const [, setFilterValues] = useAtom(ticketsAtom);

  useEffect(() => {
    if (!ticket) {
      return;
    }
    const temp = [];

    ticket.assignees.forEach((element) => {
      temp.push(element.analyst);
    });

    setAssignees(temp);
  }, [ticket]);

  useEffect(() => {
    if (emails.length === 0) {
      return;
    }
    let ccTemp = [];
    let bccTemp = [];
    emails.forEach((element) => {
      if (element.email !== ticket.user.email) {
        if (element.analyst_email) {
          bccTemp.push(element);
        } else {
          ccTemp.push(element);
        }
      }
    });
    setBcc(bccTemp);
    setCc(ccTemp);
  }, [emails, ticket]);

  const getTimes = useCallback(() => {
    let myTimeTemp = 0,
      totalTimeTemp = 0;

    ticket.timeLogs.forEach((log) => {
      if (log.analyst_id === user.id) {
        myTimeTemp += log.total_time;
      }
      totalTimeTemp += log.total_time;
    });
    setMyTime(myTimeTemp);
    setTotalTime(totalTimeTemp);
  }, [ticket, user]);

  useEffect(() => {
    if (!ticket) {
      return;
    }
    getTimes();
  }, [ticket, getTimes]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (emailRef.current && !emailRef.current.contains(event.target)) {
        if (!keepOpen) {
          setOpenEmailOptions(-1);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emailRef, keepOpen]);

  const handleDelete = async (id) => {
    const data = {
      ticket_id: ticket.id,
      id,
    };
    await removeEmailRequest(data);
  };

  const handleKeepOpen = (state) => {
    if (state === keepOpen) {
      return;
    }
    setKeepOpen(state);
    if (!state) {
      setOpenEmailOptions(-1);
    }
  };

  const handleKeepNoEmailsOpen = (state) => {
    if (state === keepNoEmailsOpen) {
      return;
    }
    setKeepNoEmailsOpen(state);
  };

  return (
    <Box
      sx={{ background: "#f3f3f3", p: 1.5, borderRadius: 2, display: { xs: "none", md: "block" } }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            cursor: "pointer",
            mb: 1,
          }}
          onClick={() => setOpen((prev) => !prev)}
        >
          <VersionBadge showVersion={showVersion && ticket.version === 2} badgeContent="LV2">
            <Typography variant="h5">
              &#91;{ticket.ticket_number}&#93;: {ticket?.subject}
            </Typography>
          </VersionBadge>
          {ticket?.ts_updated_by_customer > ticket?.ts_updated_by_analyst ? (
            <Typography variant="p">
              {ticket &&
                format(new Date(ticket?.ts_updated_by_customer * 1000), "dd MMM yyyy HH:mm")}
            </Typography>
          ) : (
            <Typography variant="p">
              {ticket &&
                format(new Date(ticket?.ts_updated_by_analyst * 1000), "dd MMM yyyy HH:mm")}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <TicketPriorityChange priority={ticket.priority_id} id={ticket.id} />
            <TicketStatusChange status={ticket.status_id} id={ticket.id} />
            <TicketDepartmentChange
              department={ticket.department_id}
              id={ticket.id}
              margin={true}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", marginRight: -1.2, marginTop: -2 }}>
            <ActivityHistory id={ticket.id} customer={ticket.user} />
            <Tooltip title="Internal Message">
              <span>
                <IconButton
                  sx={{ display: ticket.status_id !== 3 ? "block" : "none", width: 40, height: 40 }}
                  onClick={openInternalMessage}
                >
                  <MailLockRoundedIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Reply">
              <span>
                <IconButton
                  sx={{ display: ticket.status_id !== 3 ? "block" : "none", width: 40, height: 40 }}
                  onClick={openReply}
                >
                  <ReplyRoundedIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            {!open ? (
              <Tooltip title="Show details">
                <IconButton sx={{ width: 40, height: 40 }} onClick={() => setOpen((prev) => !prev)}>
                  <ExpandMoreRoundedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Hide details">
                <IconButton sx={{ width: 40, height: 40 }} onClick={() => setOpen((prev) => !prev)}>
                  <ExpandLessRoundedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
      <TicketOperatorsChange assignees={assignees} ticket={ticket} />
      <Collapse in={open} timeout={{ enter: 100, exit: 100 }}>
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: 1, width: "max-content" }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0,
                      verticalAlign: "top",
                    }}
                  >
                    <Typography variant="p">From</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0,
                      paddingLeft: 2,
                    }}
                  >
                    <Box sx={{ mb: 1 }}>
                      <Button
                        sx={{ textTransform: "none", fontSize: 14, p: 0, px: 1 }}
                        onClick={() => setOpenEmailOptions(-2)}
                      >
                        {ticket.user.email}
                      </Button>
                      {openEmailOptions === -2 && (
                        <Box ref={emailRef}>
                          <EmailPopup>
                            <Button
                              sx={{ textTransform: "none", width: "100%" }}
                              onClick={() => {
                                SnackbarUtils.success("Copied Successfully");
                                navigator.clipboard.writeText(ticket.user.email);
                                setOpenEmailOptions(false);
                              }}
                            >
                              Copy Email Address
                            </Button>
                            <Button
                              sx={{
                                textTransform: "none",
                                width: "100%",
                                justifyContent: "start",
                              }}
                              target="_top"
                              rel="noopener noreferrer"
                              href={`mailto:${ticket.user.email}`}
                            >
                              Send Email
                            </Button>
                            <Button
                              sx={{ textTransform: "none", width: "100%", justifyContent: "start" }}
                              onClick={() => {
                                setFilterValues((prev) => ({
                                  ...prev,
                                  from: [ticket.user.email],
                                  status: ["Any"],
                                }));
                                navigate("/All");
                              }}
                            >
                              View Tickets
                            </Button>
                          </EmailPopup>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                {(cc.length === 0 && bcc.length === 0) || keepNoEmailsOpen ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        verticalAlign: "center",
                      }}
                    >
                      <Typography variant="p">Cc</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        paddingLeft: 2,
                      }}
                    >
                      <AddEmail
                        defaultChecked={false}
                        ticket_id={ticket.id}
                        setKeepOpen={handleKeepOpen}
                        setKeepNoEmailsOpen={handleKeepNoEmailsOpen}
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  cc.length > 0 && (
                    <TableRow>
                      <TableCell
                        sx={{
                          border: "none",
                          p: 0,
                          verticalAlign: "top",
                        }}
                      >
                        <Typography variant="p">Cc</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          p: 0,
                          paddingLeft: 2,
                          maxWidth: { md: "60vw", lg: "70vw", xl: "78vw" },
                        }}
                      >
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          {cc.map((element) => (
                            <Box key={element.id} sx={{ marginLeft: 1, marginBottom: 1 }}>
                              <Chip
                                label={element.email}
                                onClick={() => setOpenEmailOptions(element.id)}
                                onDelete={() => handleDelete(element.id)}
                              />
                              {openEmailOptions === element.id && (
                                <Box ref={emailRef}>
                                  <EmailPopup>
                                    <Button
                                      sx={{ textTransform: "none", width: "100%" }}
                                      onClick={() => {
                                        SnackbarUtils.success("Copied Successfully");
                                        navigator.clipboard.writeText(element.email);
                                        setOpenEmailOptions(false);
                                      }}
                                    >
                                      Copy Email Address
                                    </Button>
                                    <Button
                                      sx={{
                                        textTransform: "none",
                                        width: "100%",
                                        justifyContent: "start",
                                      }}
                                      target="_top"
                                      rel="noopener noreferrer"
                                      href={`mailto:${element.email}`}
                                    >
                                      Send Email
                                    </Button>
                                    <AddEmail
                                      defaultChecked={false}
                                      ticket_id={ticket.id}
                                      setKeepOpen={handleKeepOpen}
                                    />
                                  </EmailPopup>
                                </Box>
                              )}
                            </Box>
                          ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                )}
                {bcc.length > 0 && (
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        verticalAlign: "top",
                      }}
                    >
                      <Typography variant="p">Bcc</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        paddingLeft: 2,
                        maxWidth: { md: "60vw", lg: "70vw", xl: "78vw" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {bcc.map((element) => (
                          <Box key={element.id} sx={{ marginLeft: 1, marginBottom: 1 }}>
                            <Chip
                              label={element.email}
                              onClick={() => setOpenEmailOptions(element.id)}
                              onDelete={() => handleDelete(element.id)}
                            />
                            {openEmailOptions === element.id && (
                              <Box ref={emailRef}>
                                <EmailPopup>
                                  <Button
                                    sx={{ textTransform: "none", width: "100%" }}
                                    onClick={() => {
                                      SnackbarUtils.success("Copied Successfully");
                                      navigator.clipboard.writeText(element.email);
                                      setOpenEmailOptions(false);
                                    }}
                                  >
                                    Copy Email Address
                                  </Button>
                                  <Button
                                    sx={{
                                      textTransform: "none",
                                      width: "100%",
                                      justifyContent: "start",
                                    }}
                                    target="_top"
                                    rel="noopener noreferrer"
                                    href={`mailto:${element.email}`}
                                  >
                                    Send Email
                                  </Button>
                                  <AddEmail
                                    defaultChecked={true}
                                    ticket_id={ticket.id}
                                    setKeepOpen={handleKeepOpen}
                                  />
                                </EmailPopup>
                              </Box>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow sx={{ p: 0 }}>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0,
                    }}
                  >
                    <Typography variant="p">Reminder</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0,
                      paddingLeft: 2,
                    }}
                  >
                    <Reminders reminders={ticket.reminders} ticket_id={ticket.id} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0,
                    }}
                  >
                    <Typography variant="p">My Time</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0,
                      paddingLeft: 2,
                    }}
                  >
                    <EditTimeLog
                      timeLogs={ticket.timeLogs.filter((log) => log.analyst_id === user.id)}
                      ticket_id={ticket.id}
                      analyst_id={user.id}
                      myTime={myTime}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0,
                    }}
                  >
                    <Typography variant="p">Total Time</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0,
                      paddingLeft: 2,
                    }}
                  >
                    <ViewCompleteLog timeLogs={ticket.timeLogs} totalTime={totalTime} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Collapse>
    </Box>
  );
};

DesktopHeader.propTypes = {
  ticket: object,
  emails: array,
  openReply: func,
  openInternalMessage: func,
  showVersion: bool,
};

export { DesktopHeader };
