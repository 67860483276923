import { Box, IconButton, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import MessageIcon from "@mui/icons-material/Message";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTimeout(() => {
          setOpen();
        }, 90);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, setOpen]);

  return (
    <>
      <div style={{ marginRight: 20 }}>
        <IconButton onClick={() => setOpen((prev) => !prev)}>
          <SettingsIcon sx={{ color: "#424242" }} fontSize="large" />
        </IconButton>
      </div>
      {open && (
        <Box
          ref={wrapperRef}
          sx={{
            position: "absolute",
            width: 250,
            background: "#fcfcfc",
            top: "75px",
            borderRadius: 3,
            boxShadow: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: 1.5,
              padding: 0.5,
              borderRadius: 2,
              cursor: "pointer",
              "&:hover": { background: "#f3f3f3" },
            }}
            onClick={() => {
              navigate("/cannedMessages");
              setOpen(false);
            }}
          >
            <MessageIcon sx={{ fontSize: 30, marginTop: 0.5, color: "#424242" }} />
            <Typography variant="p" sx={{ fontSize: 17, marginLeft: 2 }}>
              Canned Messages
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export { Settings };
