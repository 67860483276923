import axiosInstance from "src/service/axios/axiosInstance";

export const deleteCannedMessageRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/cannedMessages/deleteCannedMessage",
      data: {
        ids: data.ids,
      },
    });
  } catch (err) {
    return err.response;
  }
};
