import { Box, CircularProgress, Fab, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { allTicketsRequest } from "src/api/allTicketsRequest";
import { useDispatch, useSelector } from "react-redux";
import { TicketsForm } from "src/components/TicketsForm";
import { addTicket, setTickets, updateTickets } from "src/redux/actions/ticketsActions";
import { filteredTicketsRequest } from "src/api/filteredTicketsRequest";
import { SocketContext } from "src/context/socket";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { setNumber, setNumberLoading } from "src/redux/actions/numberActions";
import { updateUser } from "src/redux/actions/seUser";
import { useAtom } from "jotai";
import { defaultTicketsSearchValues, ticketsAtom } from "src/components/Searchbar";
import { ActiveTicketsFilters } from "src/components/ActiveTicketsFilters";
import { compareTwoObjects } from "src/utils/compareTwoObjects";

const statusesId = { Open: [0, 1], "On-hold": 2, Closed: 3, Any: [0, 1, 2, 3] };

const Tickets = ({ sidebar }) => {
  const [loading, setLoading] = useState(1);
  const { department } = useParams();
  const location = useLocation();
  const { departments } = useSelector((state) => state.departments);
  const { user } = useSelector((state) => state.user);
  const { tickets } = useSelector((state) => state.tickets);
  const [responseTemp, setResponseTemp] = useState([]);
  const [newTicketTemp, setNewTicketTemp] = useState(null);
  const worker = useMemo(
    () => new Worker(new URL("src/longProcesses/ticketsWorker.js", import.meta.url)),
    []
  );
  const bottom = useRef(false);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const getMoreTickets = useRef(false);
  const [values, setValues] = useAtom(ticketsAtom);

  useEffect(() => {
    document.title = "Tickets";
  });

  useEffect(() => {
    if (department === "All") {
      return;
    }
    if (!departments || departments.length === 0) {
      return;
    }
    const isDepartmentExists = departments.filter(
      (dep) => dep.title.replaceAll(" ", "") === department
    )[0];
    if (!isDepartmentExists) {
      let defaultDep;
      if (user.departments.length === 1) {
        defaultDep = departments.filter((dep) => dep.id === user.departments[0].department_id)[0];
      } else {
        defaultDep = departments.filter((dep) => dep.id === user.default_dep_id)[0];
      }
      if (!defaultDep) {
        navigate("/All");
      } else {
        navigate(`/${defaultDep?.title.replaceAll(" ", "")}`);
      }
    }
  }, [departments, department, user]);

  useEffect(() => {
    socket.on("ticketChanged", (response) => {
      setResponseTemp(response);
    });
    socket.on("newTicket", (response) => {
      setTimeout(() => {
        if (!compareTwoObjects(values, defaultTicketsSearchValues, ["department", "isLoading"])) {
          return;
        }
        const id = response.department_id;
        const isAllowed = user.departments.filter((dep) => dep.department_id === id)[0];
        if (!isAllowed) {
          return;
        }
        setNewTicketTemp(response);
      }, 200);
    });
    return () => {
      socket.off("ticketChanged");
      socket.off("newTicket");
    };
    //eslint-disable-next-line
  }, [user, values]);

  useEffect(() => {
    if (responseTemp.length === 0) {
      return;
    }
    let id;
    if (department !== "All") {
      id = departments.filter((dep) => dep.title.replaceAll(" ", "") === department)[0]?.id;
    }
    if (window.location.hash.includes("filter")) {
      return;
    }
    dispatch(updateTickets({ data: responseTemp, dep: id }));
  }, [responseTemp, department, departments]);

  useEffect(() => {
    if (!newTicketTemp) {
      return;
    }
    if (window.location.hash.includes("filter")) {
      return;
    }
    let id;
    if (department !== "All") {
      id = departments.filter((dep) => dep.title.replaceAll(" ", "") === department)[0]?.id;
    }
    const show = !!(
      !id ||
      (newTicketTemp.data &&
        newTicketTemp.data.department_id &&
        newTicketTemp.data.department_id === id)
    );
    if (!show) {
      return;
    }
    dispatch(addTicket({ data: newTicketTemp, dep: id }));
  }, [newTicketTemp, dispatch, department, departments]);

  const getParams = useCallback(() => {
    if (compareTwoObjects(values, defaultTicketsSearchValues, ["department", "isLoading"])) {
      if (values.isLoading) {
        setValues((prev) => ({ ...prev, isLoading: false }));
        return { skip: true };
      }
      return;
    }

    const statusesTemp =
      values.status && values.status.length > 0 ? values.status : ["Open", "On-hold"];
    let statuses = [];
    statusesTemp.forEach((status) => {
      if (!status) {
        return;
      }
      statuses.push(statusesId[status]);
    });

    const version = values.version === "any" ? "" : values.version;

    return {
      ...values,
      status: statuses,
      version,
      skip: false,
    };
  }, [values, setValues]);

  const initialGetTickets = useCallback(async () => {
    if (!departments || departments.length === 0) {
      return;
    }
    let temp;
    const params = getParams();
    if (params && params?.skip) {
      return;
    }
    if (department !== "All") {
      temp = departments.filter(
        (dep) => dep.title.replace(/\s/g, "").toLowerCase() === department.toLowerCase()
      )[0];
    }
    if (
      department !== "All" &&
      !user.departments.filter((dep) => dep?.department_id === temp.id)[0] &&
      temp.id !== 1
    ) {
      navigate("/All");
      return;
    }
    dispatch(setNumberLoading(true));
    let response;
    if (params) {
      response = await filteredTicketsRequest(
        temp?.id ?? "all",
        new URLSearchParams({ ...params, offset: 0 })
      );
    } else {
      response = await allTicketsRequest(temp?.id || -1, 0);
    }
    if (response.status === 200) {
      worker.postMessage({ data: response.data, user_dep: user.departments });
      dispatch(setTickets(response.data.tickets));
      if (
        response.data.allowedDepartments.length > 0 &&
        JSON.stringify(response.data.allowedDepartments) !== JSON.stringify(user.departments)
      ) {
        dispatch(updateUser(response.data.allowedDepartments));
      }
    }

    bottom.current = false;
    getMoreTickets.current = true;
    setLoading(0);
  }, [dispatch, navigate, user.departments, department, departments, values]);

  const getTickets = useCallback(async () => {
    if (!getMoreTickets.current) {
      return;
    }
    let temp;
    const params = getParams();
    if (department !== "All") {
      temp = departments.filter((dep) => dep.title.replace(/\s/g, "") === department)[0];
    }
    setLoading(2);
    let response;
    if (params) {
      response = await filteredTicketsRequest(
        temp?.id ?? "all",
        new URLSearchParams({
          ...params,
          offset: tickets.length,
        })
      );
    } else {
      response = await allTicketsRequest(temp?.id || -1, tickets.length);
    }
    if (response.status === 200) {
      dispatch(setTickets([...tickets, ...response.data.tickets]));
    }

    if (response.data.tickets.length === 0) {
      getMoreTickets.current = false;
    } else {
      bottom.current = false;
    }
    setLoading(0);
  }, [department, departments, dispatch, tickets, getParams, values]);

  const handleScroll = useCallback(
    (e) => {
      if (
        window.innerHeight + e.target.documentElement.scrollTop >=
        document.getElementById("ticketForm").scrollHeight + 150
      ) {
        if (!bottom.current && tickets.length >= 70) {
          bottom.current = true;
          getTickets();
        }
      }
    },
    [tickets, getTickets]
  );

  useEffect(() => {
    worker.onmessage = (response) => {
      dispatch(setNumber(response.data));
    };
  }, []);

  useEffect(() => {
    setLoading(1);
  }, [values, location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    initialGetTickets();
  }, [initialGetTickets, values, location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [handleScroll]);

  return (
    <>
      <ActiveTicketsFilters />
      <Box
        id="ticketForm"
        sx={{
          background: { md: "white" },
          marginRight: { xs: 2, md: 4 },
          marginLeft: { xs: 2, md: 4 },
          marginTop: 1,
          marginBottom: 5,
          borderRadius: 4,
          paddingY: { xs: 0.7, md: 3 },
          minHeight: "65vh",
          userSelect: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading === 1 ? (
          <CircularProgress color="primary" size={70} />
        ) : tickets.length > 0 ? (
          <Box sx={{ width: "100%", alignSelf: "start" }}>
            {department !== "All" && !location.pathname.includes("filter") && (
              <Typography
                variant="p"
                sx={{
                  display: { xs: "inline-flex", md: "none" },
                  background: "#fcfcfc",
                  borderRadius: 2,
                  px: 1,
                  py: 0.5,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                {
                  departments.filter((dep) => dep.title.replaceAll(" ", "") === department)[0]
                    ?.title
                }
              </Typography>
            )}
            <TicketsForm tickets={tickets} sidebar={sidebar} />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20px",
                marginTop: 2,
              }}
            >
              <CircularProgress
                color="primary"
                size={30}
                sx={{ visibility: loading === 2 ? "visible" : "hidden" }}
              />
              {loading === 2 && (
                // <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginLeft: 2 }}>Loading</Typography>
                // </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Typography
            sx={{
              color: "#c4c4c4",
              fontFamily: ["Open Sans"].join(","),
              fontSize: { xs: 35, md: 47 },
            }}
          >
            No Tickets
          </Typography>
        )}
        <Fab
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: { xs: 10, md: 25 },
            right: { xs: 10, md: 34 },
            zIndex: 100,
            color: "white",
            background: "#267aad",
            "&:hover": {
              background: "#1976d2",
            },
          }}
          onClick={() => navigate("/addTicket")}
        >
          <AddRoundedIcon />
        </Fab>
      </Box>
    </>
  );
};

Tickets.propTypes = {
  sidebar: PropTypes.bool,
};

export { Tickets };
