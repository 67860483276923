import { Box, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SearchOptions } from "src/components/SearchOptions";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { bool } from "prop-types";
import { useAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { compareTwoObjects } from "src/utils/compareTwoObjects";

export const defaultTicketsSearchValues = {
  from: [],
  assignee: [],
  after: "",
  before: "",
  status: [],
  text: "",
  version: "any",
  isLoading: true,
};

export const defaultCannedMessagesSearchValues = {
  createdBy: "",
  after: "",
  before: "",
  text: "",
};

const storage = createJSONStorage(() => sessionStorage);

export const ticketsAtom = atomWithStorage("tickets", defaultTicketsSearchValues, storage);

export const cannedMessagesAtom = atomWithStorage(
  "canned-messages",
  defaultCannedMessagesSearchValues,
  storage
);

export const departmentAtom = atomWithStorage("department", "All", storage);

const Searchbar = ({ sidebar }) => {
  const location = useLocation();
  const [openOptions, setOpenOptions] = useState(false);
  const [from, setFrom] = useState([]);
  const [text, setText] = useState("");
  const [department, setDepartment] = useState("");
  const [assignee, setAssignee] = useState([]);
  const [after, setAfter] = useState("");
  const [before, setBefore] = useState("");
  const [status, setStatus] = useState([]);
  const [version, setVersion] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const { operators } = useSelector((state) => state.operators);
  const { departments } = useSelector((state) => state.departments);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const [ticketsValues, setTicketsValues] = useAtom(ticketsAtom);
  const [cannedMessagesValues, setCannedMessagesValues] = useAtom(cannedMessagesAtom);
  const [, setGlobalDepartment] = useAtom(departmentAtom);

  const setOpen = () => {
    setOpenOptions((prev) => !prev);
  };

  const getCannedMessagesParams = useCallback(() => {
    let newCreator;
    if (cannedMessagesValues.createdBy === 0) {
      newCreator = "administrator";
    } else {
      newCreator =
        operators &&
        operators.filter((op) => op.id === parseInt(cannedMessagesValues.createdBy))[0]?.email;
    }
    setAfter(cannedMessagesValues.after);
    setBefore(cannedMessagesValues.before);
    setText(cannedMessagesValues.text);
    setCreatedBy(newCreator);
  }, [cannedMessagesValues]);

  const getTicketsParams = useCallback(() => {
    const temp = location.pathname.split("/")[1];
    const dep = departments.filter(
      (Dep) => Dep.title.replaceAll(" ", "").toLowerCase() === temp.toLowerCase()
    )[0];
    if (dep === undefined && temp !== "All") {
      return;
    }
    setDepartment(temp);
    setGlobalDepartment(temp);

    let fromTemp = [];

    ticketsValues.from.forEach((element) => {
      fromTemp.push({ email: element });
    });

    setFrom(fromTemp);

    let newAssignee = [];
    ticketsValues.assignee?.forEach((element) => {
      newAssignee.push(operators && operators.filter((op) => op.id === parseInt(element))[0]);
    });
    setAssignee(newAssignee);
    setText(ticketsValues.text);
    setAfter(ticketsValues.after);
    setBefore(ticketsValues.before);
    setStatus(ticketsValues.status);
    setVersion(ticketsValues.version);
  }, [ticketsValues, location, departments, operators]);

  useEffect(() => {
    if (location.pathname.includes("cannedMessages")) {
      getCannedMessagesParams();
      return;
    }
    getTicketsParams();
  }, [location, getCannedMessagesParams, getTicketsParams]);

  const handleFromChange = (newFrom) => {
    setFrom(
      newFrom.map((el) => {
        return { email: el.email };
      })
    );
  };

  const handleDepartmnetChange = (newDepartment) => {
    setDepartment(newDepartment);
  };

  const handleAssigneeChange = (newAssignee) => {
    setAssignee(newAssignee);
  };

  const handleUpdatedFromChange = (newFrom) => {
    setAfter(newFrom);
  };

  const handleUpdatedToChange = (newTo) => {
    setBefore(newTo);
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  const handleCreatedByChange = (newCreator) => {
    setCreatedBy(newCreator);
  };

  const handleVersionChange = (newVersion) => {
    setVersion(newVersion);
  };

  const getFilteredTickets = async () => {
    let assigneeParam = [];
    if (assignee) {
      assignee.forEach((element) => {
        assigneeParam.push(element.id);
      });
    }

    let fromEmails = [];
    from.forEach((user) => {
      fromEmails.push(user.email);
    });

    setOpenOptions(false);
    let statusTemp = status;
    if (status.length === 0 && text) {
      statusTemp = ["", "Any"];
    }

    let filterValues = {
      ...defaultTicketsSearchValues,
      status: statusTemp,
      from: fromEmails,
      assignee: assigneeParam,
      after,
      before,
      version,
      text: text.trim(),
      isLoading: false,
    };

    if (
      compareTwoObjects(filterValues, defaultTicketsSearchValues, ["isLoading", "status"]) &&
      statusTemp[1] === "Any"
    ) {
      filterValues = {
        ...filterValues,
        status: [],
      };
    }

    setTicketsValues(filterValues);
    navigate(`/${department ? department : "All"}`);
  };

  const getFilteredCannedMessages = async () => {
    const creator =
      createdBy === "administrator" ? 0 : operators.filter((op) => op.email === createdBy)[0]?.id;

    setOpenOptions(false);

    setCannedMessagesValues({
      createdBy: creator ?? "",
      after,
      before,
      text: text.trim(),
    });
    navigate(`/cannedMessages`);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchRef.current.blur();
    if (location.pathname.includes("cannedMessages")) {
      getFilteredCannedMessages();
      return;
    }
    getFilteredTickets();
  };

  const clearParams = () => {
    if (location.pathname.includes("cannedMessages")) {
      setCannedMessagesValues(defaultCannedMessagesSearchValues);
    } else {
      setTicketsValues(defaultTicketsSearchValues);
    }
  };

  return (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <form onSubmit={handleSubmit}>
        <TextField
          inputRef={searchRef}
          sx={{
            width: {
              md: "400px",
              lg: "500px",
            },
            // "&:hover": { width: { md: "400px", lg: "600px" } },
            // ".Mui-focused": { width: { md: "400px", lg: "600px" } },
            transition: "width 0.15s",
            marginLeft:
              location.pathname.includes("ticket") || location.pathname === "/cannedMessage"
                ? "20px"
                : "50px",
            "& .MuiInputBase-root": {
              height: "60px",
              borderRadius: 10,
              background: "#fcfcfc",
              "&.Mui-focused fieldset": { borderColor: "#339695", boxShadow: 1 },
            },
          }}
          value={text}
          onChange={handleChange}
          placeholder={
            location.pathname.includes("/cannedMessage")
              ? "Search canned messages"
              : "Search tickets"
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="Search">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {location.pathname.includes("filter") && (
                  <Tooltip title={"Clear search form"}>
                    <IconButton onClick={clearParams}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!openOptions && (
                  <Tooltip title={"Show search options"}>
                    <IconButton onClick={setOpen}>
                      <TuneIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      </form>
      {openOptions && (
        <SearchOptions
          route={location.pathname}
          setOpen={setOpen}
          openOptions={openOptions}
          submit={handleSubmit}
          sidebar={sidebar}
          from={from}
          setFrom={handleFromChange}
          department={department}
          setDepartment={handleDepartmnetChange}
          assignee={assignee}
          setAssignee={handleAssigneeChange}
          after={after}
          setAfter={handleUpdatedFromChange}
          before={before}
          setBefore={handleUpdatedToChange}
          status={status}
          setStatus={handleStatusChange}
          version={version}
          setVersion={handleVersionChange}
          createdBy={createdBy}
          setCreatedBy={handleCreatedByChange}
          clearSearch={clearParams}
        />
      )}
    </Box>
  );
};

export { Searchbar };

Searchbar.propTypes = {
  sidebar: bool,
};
