import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { func, object } from "prop-types";

const AttachmentUploadCard = ({ file, removeFile }) => {
  return (
    <Box
      sx={{
        background: "#f7f7f7",
        width: { md: "fit-content" },
        maxWidth: { xs: "92%", md: "100%" },
        paddingLeft: 2,
        paddingRight: 1,
        py: 0.5,
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        marginLeft: { md: 2 },
        marginBottom: 1,
        justifyContent: "space-between",
      }}
    >
      <a
        href={URL.createObjectURL(file.file)}
        download={file.file.name}
        style={{
          textDecoration: "none",
          color: "#326ab3",
          marginTop: -4,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {file.file.name}
      </a>
      <Tooltip title="Remove attachment">
        <IconButton sx={{ width: 25, height: 25 }} onClick={() => removeFile(file.id)}>
          <CloseIcon sx={{ fontSize: 17 }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

AttachmentUploadCard.propTypes = {
  file: object,
  removeFile: func,
};

export { AttachmentUploadCard };
