import { SET_DEPARTMENTS } from "src/redux/actionTypes/setDepartmentsType";

const initialState = {
  departments: null,
  isLoading: true,
};

export default function departmentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEPARTMENTS:
      return {
        departments: action.payload.sort((a, b) => {
          return b.priority - a.priority;
        }),
        isLoading: false,
      };
    default:
      return state;
  }
}
