import React, { useState } from "react";
import PropTypes, { func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { CopyUserDetails } from "src/components/TicketsForm/CopyUserDetails";

export const NameField = ({ ticket, clearHover, setPreventAction }) => {
  const [hover, setHover] = useState(false);
  return (
    <Box
      sx={{ width: "16%", display: "flex", alingItems: "center", position: "relative" }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Typography
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          marginRight: 2,
          color: ticket?.status_id === 3 ? "#8a8a8a" : "black",
          marginTop: 0.4,
          fontWeight:
            ticket.status_id !== 3 &&
            ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
            "bold",
        }}
      >
        {ticket?.user.first_name} {ticket?.user.last_name}
      </Typography>
      {hover && (
        <CopyUserDetails
          bgColor={ticket?.status_id === 2 ? "#c4c4c4" : "#f7f7f7"}
          user={ticket.user}
          clearHover={() => {
            setHover(false);
            clearHover();
          }}
          setPreventAction={setPreventAction}
        />
      )}
    </Box>
  );
};

NameField.propTypes = {
  ticket: PropTypes.object,
  clearHover: func,
  setPreventAction: func,
};
