import axiosInstance from "src/service/axios/axiosInstance";

export const editReminderRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/tickets/editReminder",
      data: {
        id: data.id,
        date: data.date,
        analyst_only: data.analyst_only,
        message: data.message,
        completed: data.completed,
        ticket_id: data.ticket_id,
      },
    });
  } catch (err) {
    return err.response;
  }
};
