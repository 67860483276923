import React from "react";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { format, isToday } from "date-fns";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { returnGravatar } from "src/utils/getGravatar";
import { stringSmallAvatar } from "src/utils/ticketAvatar";
import useLongPress from "src/hooks/useLongPress";
import { bool, func, object } from "prop-types";

const MobileCard = ({ message, checked, handleSelectChange, selected }) => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const onLongPress = () => {
    if (message.analyst_id === user.id) {
      handleSelectChange(message.id);
    }
  };

  const onClick = () => {
    if (selected) {
      if (message.analyst_id === user.id) {
        handleSelectChange(message.id);
      }
    } else {
      navigate(`/cannedMessage?id=${message.id}`);
    }
  };
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  return (
    <Box
      sx={{
        display: { xs: "block", md: "none" },
        width: "100%",
        marginBottom: 1,
        userSelect: "none",
      }}
      {...longPressEvent}
    >
      <Paper
        sx={{
          background:
            checked === true ? "#2196f3" : message.analyst_id === user.id ? "white" : "#c9c9c9",
          padding: 1.2,
          boxShadow: 2,
          display: "flex",
          borderRadius: 2,
          color: checked ? "white" : "black",
          alingItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            {message.analyst_id === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar {...stringSmallAvatar(`Administrator`)} />
                  <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", marginLeft: 2 }}>
                    Administrator
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alingItems: "center", justifyContent: "end" }}>
                  {isToday(new Date(message.ts_updated * 1000)) ? (
                    <Typography variant="p" sx={{ marginLeft: 1, fontSize: "12px" }}>
                      {format(new Date(message.ts_updated * 1000), "HH:mm")}
                    </Typography>
                  ) : format(new Date(message.ts_updated * 1000), "yyyy") <
                    format(new Date(), "yyyy") ? (
                    <Typography variant="p" sx={{ marginLeft: 1, fontSize: "12px" }}>
                      {format(new Date(message.ts_updated * 1000), "MMM `yy")}
                    </Typography>
                  ) : (
                    <Typography variant="p" sx={{ marginLeft: 1, fontSize: "12px" }}>
                      {format(new Date(message.ts_updated * 1000), "dd MMM")}
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    {...stringSmallAvatar(
                      `${message.analyst.first_name} ${message.analyst.last_name}`
                    )}
                    src={returnGravatar(message.analyst.email)}
                  />
                  <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", marginLeft: 2 }}>
                    {message.analyst.first_name} {message.analyst.last_name}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alingItems: "center", justifyContent: "end" }}>
                  {isToday(new Date(message.ts_updated * 1000)) ? (
                    <Typography variant="p" sx={{ marginLeft: 1, fontSize: "12px" }}>
                      {format(new Date(message.ts_updated * 1000), "HH:mm")}
                    </Typography>
                  ) : format(new Date(message.ts_updated * 1000), "yyyy") <
                    format(new Date(), "yyyy") ? (
                    <Typography variant="p" sx={{ marginLeft: 1, fontSize: "12px" }}>
                      {format(new Date(message.ts_updated * 1000), "MMM `yy")}
                    </Typography>
                  ) : (
                    <Typography variant="p" sx={{ marginLeft: 1, fontSize: "12px" }}>
                      {format(new Date(message.ts_updated * 1000), "dd MMM")}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ width: "100%", marginTop: 0.5 }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <span style={{ fontWeight: "bold" }}>{message.title}</span> - {message.content}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

MobileCard.propTypes = {
  message: object,
  checked: bool,
  handleSelectChange: func,
  selected: bool,
};

export { MobileCard };
