import { Box } from "@mui/material";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cannedMessageRequest } from "src/api/cannedMessageRequest";
import { CannedMessageForm } from "src/components/CannedMessageForm";
import { SocketContext } from "src/context/socket";

const CannedMessage = () => {
  const [searchParams] = useSearchParams();
  const [cannedMessage, setCannedMessage] = useState(null);
  const socket = useContext(SocketContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Canned Message Details";
  });

  const getCannedMessage = useCallback(async () => {
    const id = searchParams.get("id");
    const response = await cannedMessageRequest(id);
    if (response.status === 200) {
      setCannedMessage(response.data.canned_message);
      if (!response.data.canned_message) {
        navigate("/cannedMessages");
      }
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    getCannedMessage();
  }, [getCannedMessage]);

  useEffect(() => {
    if (!cannedMessage) {
      return;
    }
    socket.on("cannedMessageRemoved", (response) => {
      if (response.includes(cannedMessage.id)) {
        navigate("/cannedMessages");
      }
    });
    socket.on("cannedMessageEdited", (response) => {
      if (response.id === cannedMessage.id) {
        setCannedMessage(response);
      }
    });

    return () => {
      socket.off("cannedMessageRemoved");
      socket.off("cannedMessageEdited");
    };
    //eslint-disable-next-line
  }, [cannedMessage]);

  return (
    <Box
      sx={{
        background: "white",
        marginRight: { xs: 2, md: 4 },
        marginLeft: { xs: 2, md: 4 },
        marginTop: 1,
        marginBottom: 5,
        borderRadius: 4,
        padding: { xs: 0.7, md: 3 },
        minHeight: "65vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {cannedMessage && <CannedMessageForm cannedMessage={cannedMessage} />}
    </Box>
  );
};

export { CannedMessage };
