import axiosInstance from "src/service/axios/axiosInstance";

export const removeEmailRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/emails/remove",
      data: {
        id: data.id,
        ticket_id: data.ticket_id,
      },
    });
  } catch (err) {
    return err.response;
  }
};
