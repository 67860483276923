import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { editCannedMessageRequest } from "src/api/editCannedMessageRequest";
import { returnGravatar } from "src/utils/getGravatar";
import { stringSmallAvatar } from "src/utils/ticketAvatar";
import { DeleteCannedMessage } from "src/components/DeleteCannedMessage";
import { object } from "prop-types";

const CannedMessageForm = ({ cannedMessage }) => {
  const [title, setTitle] = useState(cannedMessage.title);
  const [content, setContent] = useState(cannedMessage.content);
  const [edit, setEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [active, setActive] = useState(false);
  const { user } = useSelector((state) => state.user);
  const messageRef = useRef();

  useEffect(() => {
    if (!cannedMessage) {
      return;
    }
    setTitle(cannedMessage.title);
    setContent(cannedMessage.content);
  }, [cannedMessage]);

  const handleDeleteMessageChange = (newState) => {
    setDeleteOpen(newState);
  };

  const handleSubmit = async () => {
    const data = {
      title,
      content,
      id: cannedMessage.id,
    };
    const response = await editCannedMessageRequest(data);
    if (response.status >= 400) return;
    setEdit(false);
  };

  return (
    <Box sx={{ background: "#f3f3f3", p: 1.5, borderRadius: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
        <TextField
          InputProps={{
            readOnly: !edit,
            disableUnderline: !edit,
            inputProps: {
              sx: {
                fontSize: { xs: 20, md: 24 },
                paddingLeft: 1,
                py: 1,
              },
            },
          }}
          sx={{ width: "100%" }}
          size="small"
          label={edit ? "Title" : null}
          variant={edit ? "outlined" : "standard"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Typography
          variant="p"
          sx={{ mt: 1.5, display: { xs: "none", md: "block", width: 180, textAlign: "end" } }}
        >
          {format(new Date(cannedMessage.ts_updated * 1000), "dd MMM yyyy HH:mm")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
          {cannedMessage?.analyst_id === 0 ? (
            <>
              <Avatar {...stringSmallAvatar(`Administrator`)} />
              <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", marginLeft: 1 }}>
                Administrator
              </Typography>
            </>
          ) : (
            <>
              <Avatar
                {...stringSmallAvatar(
                  cannedMessage?.analyst?.first_name && cannedMessage?.analyst?.last_name
                    ? `${cannedMessage?.analyst?.first_name} ${cannedMessage?.analyst?.last_name}`
                    : cannedMessage?.analyst?.first_name && !cannedMessage?.analyst?.last_name
                    ? cannedMessage?.analyst?.first_name
                    : cannedMessage?.analyst?.email
                )}
                src={returnGravatar(cannedMessage?.analyst?.email)}
              />
              <Typography sx={{ marginLeft: 1 }}>
                {cannedMessage?.analyst?.first_name} {cannedMessage?.analyst?.last_name}
              </Typography>
            </>
          )}
        </Box>
        <Typography variant="p" sx={{ mt: 1, display: { xs: "block", md: "none" } }}>
          {format(new Date(cannedMessage.ts_updated * 1000), "dd MMM yyyy HH:mm")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          borderRadius: 3,
          px: edit ? (active ? "14px" : "15px") : "16px",
          py: edit ? (active ? "6px" : "7px") : "8px",
          mt: 1,
          border: edit ? (active ? "2px solid #1976d2" : "1px solid #bdbdbd") : "",
          "&:hover": {
            border: edit && !active ? "1px solid black" : "",
          },
        }}
      >
        <TextField
          id="message"
          multiline
          value={content}
          inputRef={messageRef}
          onChange={(e) => setContent(e.target.value)}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          variant="standard"
          placeholder="Message"
          minRows={4}
          sx={{
            width: "100%",
            background: "white",
            borderRadius: 2,
          }}
          InputProps={{
            readOnly: !edit,
            disableUnderline: true,
          }}
        />
      </Box>

      {cannedMessage.analyst_id === user.id && (
        <>
          <Box
            sx={{
              display: edit ? "none" : "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: 1,
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setEdit(true);
                messageRef.current.focus();
                messageRef.current.setSelectionRange(content.length, content.length);
              }}
              sx={{
                width: "80px",
                borderRadius: 5,
                background: "#267aad",
                "&:hover": {
                  background: "#1976d2",
                },
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              onClick={() => setDeleteOpen(true)}
              sx={{
                marginLeft: 1,
                width: "80px",
                borderRadius: 5,
                background: "#d13838",
                "&:hover": { background: "#b32020" },
              }}
            >
              Delete
            </Button>
          </Box>
          <Box
            sx={{
              display: edit ? "flex" : "none",
              alignItems: "center",
              justifyContent: "end",
              marginTop: 1,
            }}
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                width: "80px",
                borderRadius: 5,
                background: "#267aad",
                "&:hover": {
                  background: "#1976d2",
                },
              }}
            >
              Update
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setEdit(false);
                setTitle(cannedMessage.title);
                setContent(cannedMessage.content);
              }}
              sx={{
                marginLeft: 1,
                width: "80px",
                borderRadius: 5,
                background: "#808080",
                "&:hover": { background: "#686a6b" },
              }}
            >
              Cancel
            </Button>
          </Box>
        </>
      )}

      <DeleteCannedMessage
        open={deleteOpen}
        setOpen={handleDeleteMessageChange}
        selected={[cannedMessage.id]}
      />
    </Box>
  );
};

CannedMessageForm.propTypes = {
  cannedMessage: object,
};

export { CannedMessageForm };
