import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ProfileDropdown } from "src/components/ProfileDropdown";
import { departmentAtom, Searchbar } from "src/components/Searchbar";
import { Settings } from "src/components/Settings";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { string } from "prop-types";
import { useAtom } from "jotai";

const Header = ({ sidebar }) => {
  const [y, setY] = useState(window.scrollY);
  const [scrollUp, setScrollUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [department] = useAtom(departmentAtom);

  const handleNavigation = useCallback(() => {
    if (y > window.scrollY && !scrollUp) {
      setScrollUp(true);
    } else if (y < window.scrollY && scrollUp) {
      setScrollUp(false);
    }
    if (window.scrollY === 0) {
      setScrollUp(false);
    }
    setY(window.scrollY);
  }, [y, scrollUp]);

  useEffect(() => {
    window.addEventListener("scroll", () => handleNavigation());

    return () => {
      window.removeEventListener("scroll", () => handleNavigation());
    };
  }, [y, handleNavigation]);

  return (
    <Box
      sx={{
        background: "#e6eded",
        height: "80px",
        display: { xs: "none", md: "flex" },
        flexDirection: "column",
        position: scrollUp ? "fixed" : "absolute",
        width: "99vw",
        zIndex: 99,
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "90px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            marginLeft: sidebar,
            transition: "all 0.15s",
            display: "flex",
            alignItems: "center",
          }}
        >
          {location.pathname.includes("ticket") && (
            <Tooltip title="Back">
              <IconButton
                sx={{ marginLeft: 5, width: 40, height: 40 }}
                onClick={() => {
                  navigate(`/${department}`);
                }}
              >
                <WestRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
          {location.pathname === "/cannedMessage" && (
            <Tooltip title="Back">
              <IconButton
                sx={{ marginLeft: 5, width: 40, height: 40 }}
                onClick={() => {
                  navigate("/cannedMessages");
                }}
              >
                <WestRoundedIcon />
              </IconButton>
            </Tooltip>
          )}

          <Searchbar sidebar={Boolean(sidebar === "300px")} />
        </Box>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Settings />
          <div style={{ marginRight: "40px" }}>
            <ProfileDropdown />
          </div>
        </div>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  sidebar: string,
};

export { Header };
