import * as jose from "jose";

const generateJWT = async (id) => {
  const alg = process.env.REACT_APP_SOCKET_ALG;
  const secret = new TextEncoder().encode(process.env.REACT_APP_SOCKET_SECRET);

  const jwt = await new jose.SignJWT({})
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer(id)
    .setExpirationTime("1m")
    .sign(secret);

  return jwt;
};

export { generateJWT };
