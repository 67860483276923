import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { AddTicketForm } from "src/components/AddTicketForm";

const AddTicket = () => {
  useEffect(() => {
    document.title = "Add Ticket";
  });
  return (
    <Box
      sx={{
        background: { md: "white" },
        marginRight: { xs: 2, md: 4 },
        marginLeft: { xs: 2, md: 4 },
        marginTop: 1,
        marginBottom: 5,
        borderRadius: 4,
        paddingY: { xs: 0.7, md: 3 },
        paddingX: { md: 2 },
        minHeight: "65vh",
        userSelect: "none",
      }}
    >
      <AddTicketForm />
    </Box>
  );
};

export { AddTicket };
