import axiosInstance from "src/service/axios/axiosInstance";

export const changeTicketsPriorityRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/tickets/changePriority",
      data: {
        ids: data.ids,
        priority: data.priority,
      },
    });
  } catch (err) {
    return err.response;
  }
};
