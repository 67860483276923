import React from "react";
import { Box, Button, IconButton, Menu } from "@mui/material";
import { func, object, string } from "prop-types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import snackbarUtils from "src/utils/SnackbarUtils";

export const CopyUserDetails = ({ bgColor, user, clearHover, setPreventAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPreventAction(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    clearHover();
    setPreventAction(false);
  };

  return (
    <>
      <Box
        position="absolute"
        right={10}
        sx={{
          bgcolor: bgColor,
        }}
        pl={0.5}
        height="100%"
        display="flex"
        alignItems="center"
      >
        <IconButton
          id="positioned-button"
          aria-controls={open ? "positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Box>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            background: "#fcfcfc",
            borderRadius: 10,
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
            margin: 0,
            width: "max-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          },
        }}
      >
        <Button
          fullWidth
          sx={{ textTransform: "none", justifyContent: "flex-start" }}
          onClick={() => {
            snackbarUtils.success("Copied Successfully");
            navigator.clipboard.writeText(`${user.first_name ?? ""} ${user.last_name ?? ""}`);
            handleClose();
          }}
        >
          Copy Name
        </Button>
        <Button
          fullWidth
          sx={{ textTransform: "none", justifyContent: "flex-start" }}
          onClick={() => {
            snackbarUtils.success("Copied Successfully");
            navigator.clipboard.writeText(user.email ?? "");
            handleClose();
          }}
        >
          Copy Email
        </Button>
        <Button
          fullWidth
          sx={{ textTransform: "none", justifyContent: "flex-start" }}
          onClick={() => {
            snackbarUtils.success("Copied Successfully");
            navigator.clipboard.writeText(
              `${user.first_name ?? ""} ${user.last_name ?? ""} ${`<${user.email}>` ?? ""}`
            );
            handleClose();
          }}
        >
          Copy Name and Email
        </Button>
      </Menu>
    </>
  );
};

CopyUserDetails.propTypes = {
  bgColor: string,
  user: object,
  clearHover: func,
  setPreventAction: func,
};
