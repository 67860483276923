import { Chip, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { array } from "prop-types";
import { format, isThisYear, isToday, isTomorrow } from "date-fns";

const ReminderChip = ({ reminders }) => {
  const [label, setLabel] = useState("");
  const [tooltip, setTooltip] = useState("");

  useEffect(() => {
    if (!reminders || reminders.length <= 0) {
      return;
    }
    const time = Math.floor(new Date().getTime() / 1000);
    const reminder = [...(reminders ?? [])]
      .filter((reminder) => reminder.ts_scheduled > time)
      .sort((a, b) => a.ts_scheduled - b.ts_scheduled)[0];
    if (!reminder) {
      return;
    }
    let label;
    const date = reminder.ts_scheduled * 1000;
    if (isToday(date)) {
      label = `Today, ${format(date, "HH:mm")}`;
    } else if (isTomorrow(date)) {
      label = `Tomorrow, ${format(date, "HH:mm")}`;
    } else if (isThisYear(date)) {
      label = `${format(date, "EEE, d MMM HH:mm")}`;
    } else {
      label = `${format(date, "EEE, d MMM yyyy HH:mm")}`;
    }
    setTooltip(reminder.message.slice(0, 50));
    setLabel(label ?? "");
  }, [reminders]);

  if (!label) {
    return <></>;
  }

  return (
    <Tooltip title={tooltip}>
      <Chip label={label} size="small" sx={{ width: "fit-content" }} />
    </Tooltip>
  );
};

ReminderChip.propTypes = {
  reminders: array,
};

export { ReminderChip };
