import { Avatar, Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { returnGravatar } from "src/utils/getGravatar";
import { stringSmallAvatar } from "src/utils/ticketAvatar";
import Linkify from "linkify-react";
import { AttachmentCard } from "src/components/AttachmentCard";
import { object } from "prop-types";

const Message = ({ message, customer }) => {
  const { operators } = useSelector((state) => state.operators);
  const [analyst, setAnalyst] = useState(null);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (message.analyst_id === 0) {
      return;
    }
    const temp = operators?.filter((op) => op.id === message.analyst_id)[0];
    setAnalyst(temp);
  }, [message, operators]);

  return (
    <Box
      sx={{
        background: "#f7f7f7",
        p: 1,
        borderRadius: 3,
        marginTop: 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {message.analyst_id === 0 && message.admin_id === 0 ? (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Avatar
              {...stringSmallAvatar(
                customer.first_name && customer.last_name
                  ? `${customer?.first_name} ${customer?.last_name}`
                  : customer.first_name && !customer.last_name
                  ? customer.first_name
                  : customer.email
              )}
              alt={
                customer.first_name && customer.last_name
                  ? `${customer?.first_name} ${customer?.last_name}`
                  : customer.first_name && !customer.last_name
                  ? customer.first_name
                  : customer.email
              }
              src={returnGravatar(customer?.email)}
            />
            <Typography variant="p" sx={{ marginLeft: 1 }}>
              {customer?.first_name} {customer?.last_name}
            </Typography>
          </Box>
        ) : message.analyst_id === user.id ? (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Avatar
              {...stringSmallAvatar(`${user.first_name} ${user.last_name}`)}
              src={returnGravatar(user.email)}
            />
            <Typography variant="p" sx={{ marginLeft: 1 }}>
              {user?.first_name} {user?.last_name}
            </Typography>
          </Box>
        ) : message.analyst_id !== user.id && message.analyst_id !== 0 ? (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Avatar
              {...stringSmallAvatar(`${analyst?.first_name} ${analyst?.last_name}`)}
              src={returnGravatar(analyst?.email)}
            />
            <Typography variant="p" sx={{ marginLeft: 1 }}>
              {analyst?.first_name} {analyst?.last_name}
            </Typography>
          </Box>
        ) : (
          message.admin_id !== 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar {...stringSmallAvatar(`Administrator`)} />
              <Typography variant="p" sx={{ marginLeft: 1 }}>
                Administrator
              </Typography>
            </Box>
          )
        )}
        <Typography variant="p" sx={{ marginTop: "2px" }}>
          {format(new Date(message.ts_added * 1000), "dd MMM yyyy HH:mm")}
        </Typography>
      </Box>
      <Box
        sx={{
          background: message.analyst_only ? "#f5efd5" : "white",
          width: "100%",
          minHeight: "80px",
          marginTop: 1,
          borderRadius: 2,
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            mx: 2,
            mt: "-10px",
          }}
        >
          <Linkify
            tagName="p"
            style={{
              fontSize: 15,
              whiteSpace: "pre-wrap",
              minHeight: "60px",
              textAlign: "left",
              wordBreak: "break-word",
            }}
          >
            {message.message}
          </Linkify>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              flexWrap: "wrap",
              marginLeft: { md: -2 },
            }}
          >
            {message?.attachments?.map((file) => (
              <AttachmentCard key={file.id} file={file} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Message.propTypes = {
  message: object,
  customer: object,
};

export { Message };
