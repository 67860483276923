import axiosInstance from "src/service/axios/axiosInstance";

export const createCustomerRequest = async (email) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/customers/createCustomer",
      data: {
        email,
      },
    });
  } catch (err) {
    return err.response;
  }
};
