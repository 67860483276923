import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { changeTicketOperatorsRequest } from "src/api/changeTicketOperatorsRequest";
import { stringAvatar } from "src/utils/createAvatar";
import { returnGravatar } from "src/utils/getGravatar";
import { style } from "src/styles/modalStyle";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { array, object } from "prop-types";

const ChangeTicketOperators = ({ assignees, ticket }) => {
  const [open, setOpen] = useState(false);
  const [operatorsTemp, setOperators] = useState([]);
  const { operators } = useSelector((state) => state.operators);
  const { user } = useSelector((state) => state.user);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (!assignees) {
      return;
    }
    let temp = [];
    assignees.forEach((assignee) => {
      temp.push(assignee.analyst);
    });
    setOperators(temp);
  }, [assignees]);

  const handleChange = async (e, values) => {
    let data;
    if (values.length > operatorsTemp.length) {
      data = {
        ticket_id: ticket.id,
        analyst_id: values[values.length - 1].id,
      };
    } else {
      const assignee = operatorsTemp.filter((x) => !values.includes(x))[0];
      data = {
        ticket_id: ticket.id,
        analyst_id: assignee.id,
      };
    }
    setFilter("");
    await changeTicketOperatorsRequest(data);
  };

  return (
    <>
      <IconButton sx={{ width: 25, height: 25, marginTop: 0.3 }} onClick={() => setOpen(true)}>
        <Avatar
          sx={{
            width: "max-content",
            fontSize: 14,
            borderRadius: 10,
            height: 25,
            background: "gray",
          }}
        >
          <AddRoundedIcon />
        </Avatar>
      </IconButton>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, background: "white" }}>
          <Typography variant="h6">Change Operators</Typography>
          <Autocomplete
            sx={{ marginTop: 2 }}
            options={operators || []}
            multiple
            disableClearable
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={operatorsTemp}
            onChange={handleChange}
            onInputChange={(e) => setFilter(e.target.value)}
            autoHighlight={true}
            filterSelectedOptions
            filterOptions={(options) => {
              let newOptions = [];
              options.forEach((option) => {
                if (option.first_name === "Unassigned") {
                  return;
                }
                if (
                  !option.departments?.filter(
                    (dep) => dep.department_id === ticket.department_id
                  )[0] &&
                  ticket.department_id !== 1
                ) {
                  return;
                }
                if (option.first_name === "My" && option.last_name === "Tickets") {
                  newOptions.push({
                    ...option,
                    first_name: user.first_name,
                    last_name: user.last_name,
                  });
                } else {
                  newOptions.push(option);
                }
              });
              if (!filter) {
                return newOptions;
              }
              newOptions = newOptions.filter((option) => {
                const name = `${option.first_name} ${option.last_name}`.toLowerCase();
                if (name.includes(filter.toLowerCase()) || option.email.includes(filter)) {
                  return option;
                } else return null;
              });
              return newOptions;
            }}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            renderTags={(values, getTagProps) => {
              if (!values) return;

              return values?.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.id}
                  label={`${option.first_name} ${option.last_name}`}
                  avatar={
                    <Avatar
                      {...stringAvatar(
                        option.last_name ? `${option.first_name} ${option.last_name}` : option.email
                      )}
                      src={returnGravatar(option.email)}
                    />
                  }
                  variant="outlined"
                />
              ));
            }}
            renderInput={(params) => <TextField {...params} label="Operators" />}
          />
          <Box sx={{ display: "flex", justifyContent: "end", marginTop: 3 }}>
            <Button
              variant="contained"
              type="button"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                background: "#808080",
                "&:hover": { background: "#686a6b" },
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ChangeTicketOperators.propTypes = {
  assignees: array,
  ticket: object,
};

export { ChangeTicketOperators };
