import React, { useState } from "react";
import { alpha, Avatar, AvatarGroup, Box, Paper, Tooltip, Typography } from "@mui/material";
import { format, isToday } from "date-fns";
import { useNavigate } from "react-router-dom";
import { returnGravatar } from "src/utils/getGravatar";
import { stringSmallAvatar } from "src/utils/ticketAvatar";
import useLongPress from "src/hooks/useLongPress";
import { colorCode } from "src/styles/priorityColorCode";
import { bool, func, object } from "prop-types";
import { CopyMenu } from "src/components/TicketsForm/CopyMenu";
import { ReminderChip } from "src/components/ReminderChip";

const MobileCard = ({ ticket, handleSelectChange, checked, selected }) => {
  const navigate = useNavigate();
  const [preventAction, setPreventAction] = useState(false);
  const onLongPress = () => {
    if (preventAction) {
      return;
    }
    handleSelectChange(ticket?.id);
  };

  const onClick = async () => {
    if (preventAction) {
      return;
    }
    if (selected) {
      handleSelectChange(ticket?.id);
    } else {
      navigate(`/ticket?id=${ticket.id}`);
    }
  };
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  return (
    <Box
      sx={{
        display: { xs: "block", md: "none" },
        width: "100%",
        marginBottom: 1,
      }}
      {...longPressEvent}
    >
      <Paper
        sx={{
          background: checked === true ? "#2196f3" : ticket?.status_id === 2 ? "#e0e0e0" : "white",
          padding: 1,
          boxShadow: 1,
          display: "flex",
          borderRadius: 2,
          alingItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "53%", display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  marginRight: 2,
                  fontSize: 13,
                  fontWeight:
                    ticket.status_id !== 3 &&
                    ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                    "bold",
                  color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                }}
              >
                {ticket?.user.first_name} {ticket?.user.last_name}
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  fontWeight:
                    ticket.status_id !== 3 &&
                    ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                    "bold",
                  fontSize: 14,
                  color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                }}
              >
                [{ticket?.ticket_number}]
              </Typography>
            </Box>
            <Box sx={{ width: "22%" }}>
              {ticket?.status_id !== 0 && ticket?.assignees.length !== 0 && (
                <AvatarGroup
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    "& .MuiAvatar-root": {
                      width: 25,
                      height: 25,
                      fontSize: 13,
                    },
                  }}
                  max={3}
                >
                  {ticket?.assignees.map((assignee) => (
                    <Avatar
                      key={assignee.id}
                      {...stringSmallAvatar(
                        `${assignee.analyst.first_name} ${assignee.analyst.last_name}`
                      )}
                      alt={`${assignee.analyst.first_name} ${assignee.analyst.last_name}`}
                      src={returnGravatar(assignee.analyst.email)}
                    />
                  ))}
                </AvatarGroup>
              )}
            </Box>
            <Box
              sx={{
                width: "25%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {ticket?.ts_updated_by_analyst > ticket?.ts_updated_by_customer ? (
                isToday(new Date(ticket?.ts_updated_by_analyst * 1000)) ? (
                  <Typography
                    variant="p"
                    sx={{
                      marginRight: 2,
                      marginTop: 0.5,
                      fontSize: "12px",
                      fontWeight:
                        ticket.status_id !== 3 &&
                        ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                        "bold",
                      color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                    }}
                  >
                    {format(new Date(ticket?.ts_updated_by_analyst * 1000), "HH:mm")}
                  </Typography>
                ) : format(new Date(ticket?.ts_updated_by_analyst * 1000), "yyyy") <
                  format(new Date(), "yyyy") ? (
                  <Typography
                    variant="p"
                    sx={{
                      marginRight: 2,
                      marginTop: 0.5,
                      fontSize: "12px",
                      fontWeight:
                        ticket.status_id !== 3 &&
                        ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                        "bold",
                      color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                    }}
                  >
                    {format(new Date(ticket?.ts_updated_by_analyst * 1000), "MMM `yy")}
                  </Typography>
                ) : (
                  <Typography
                    variant="p"
                    sx={{
                      marginRight: 2,
                      marginTop: 0.5,
                      fontSize: "12px",
                      fontWeight:
                        ticket.status_id !== 3 &&
                        ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                        "bold",
                      color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                    }}
                  >
                    {format(new Date(ticket?.ts_updated_by_analyst * 1000), "dd MMM")}
                  </Typography>
                )
              ) : isToday(new Date(ticket?.ts_updated_by_customer * 1000)) ? (
                <Typography
                  variant="p"
                  sx={{
                    marginRight: 2,
                    marginTop: 0.5,
                    fontSize: "12px",
                    fontWeight:
                      ticket.status_id !== 3 &&
                      ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                      "bold",
                    color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                  }}
                >
                  {format(new Date(ticket?.ts_updated_by_customer * 1000), "HH:mm")}
                </Typography>
              ) : format(new Date(ticket.ts_updated_by_customer * 1000), "yyyy") <
                format(new Date(), "yyyy") ? (
                <Typography
                  variant="p"
                  sx={{
                    marginRight: 2,
                    marginTop: 0.5,
                    fontSize: "12px",
                    fontWeight:
                      ticket.status_id !== 3 &&
                      ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                      "bold",
                    color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                  }}
                >
                  {format(new Date(ticket?.ts_updated_by_customer * 1000), "MMM `yy")}
                </Typography>
              ) : (
                <Typography
                  variant="p"
                  sx={{
                    marginRight: 2,
                    marginTop: 0.5,
                    paddingRight: "20px",
                    fontSize: "12px",
                    fontWeight:
                      ticket.status_id !== 3 &&
                      ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                      "bold",
                    color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                  }}
                >
                  {format(new Date(ticket?.ts_updated_by_customer * 1000), "dd MMM")}
                </Typography>
              )}
              <Box display="flex" alignItems="center" mt="-10px" mr="-10px">
                {ticket.messages.length > 0 &&
                  ticket.messages[0].admin_id === 0 &&
                  ticket.messages[0].analyst_id === 0 && (
                    <Tooltip title={`${ticket.user.first_name} ${ticket.user.last_name}`}>
                      <Avatar
                        {...stringSmallAvatar(`${ticket.user.first_name} ${ticket.user.last_name}`)}
                        alt={`${ticket.user.first_name} ${ticket.user.last_name}`}
                        src={returnGravatar(ticket.user.email)}
                      />
                    </Tooltip>
                  )}
                {ticket.messages.length > 0 &&
                  ticket.messages[0].admin_id === 0 &&
                  ticket.messages[0].analyst_id > 0 && (
                    <Tooltip
                      title={`${ticket.messages[0].analyst.first_name} ${ticket.messages[0].analyst.last_name}`}
                    >
                      <Avatar
                        {...stringSmallAvatar(
                          `${ticket.messages[0].analyst.first_name} ${ticket.messages[0].analyst.last_name}`
                        )}
                        alt={`${ticket.messages[0].analyst.first_name} ${ticket.messages[0].analyst.last_name}`}
                        src={returnGravatar(ticket.messages[0].analyst.email)}
                      />
                    </Tooltip>
                  )}
                {ticket.messages.length > 0 && ticket.messages[0].admin_id > 0 && (
                  <Tooltip title="Administrator">
                    <Avatar {...stringSmallAvatar(`Administrator`)} />
                  </Tooltip>
                )}
                <CopyMenu setPreventAction={setPreventAction} ticket={ticket} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "90%" }}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: 14,
                  color: ticket?.status_id === 3 ? "#8a8a8a" : checked ? "white" : "black",
                }}
              >
                <span
                  style={{
                    fontWeight:
                      ticket.status_id !== 3 &&
                      ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                      "bold",
                  }}
                >
                  {ticket?.subject}
                </span>{" "}
                - {ticket?.messages[ticket.messages.length - 1].message}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "10%",
                display: "flex",
                justifyContent: "end",
                marginTop: "1px",
              }}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  background: alpha(
                    colorCode[ticket?.priority?.id],
                    ticket.status_id === 3 ? 0.4 : 1
                  ),
                  borderRadius: 50,
                }}
              />
            </Box>
          </Box>
          {ticket.reminders && ticket.reminders.length > 0 && (
            <ReminderChip reminders={ticket.reminders} />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

MobileCard.propTypes = {
  ticket: object,
  handleSelectChange: func,
  checked: bool,
  selected: bool,
};

export { MobileCard };
