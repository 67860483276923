import { stringToColor } from "src/utils/stringToColor";

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 35,
      height: 35,
    },
    children: name.split(" ")[1]
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name.split(" ")[0][0]}`,
  };
}

export { stringAvatar };
