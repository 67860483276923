import { configureStore } from "@reduxjs/toolkit";
import cannedMessagesReducer from "src/redux/reducers/cannedMessagesReducer";
import departmentsReducer from "src/redux/reducers/departmentsReducer";
import numberReducer from "src/redux/reducers/numberReducer";
import operatorsReducer from "src/redux/reducers/operatorsReducer";
import preferencesReducer from "src/redux/reducers/preferencesReducer";
import ticketsReducer from "src/redux/reducers/ticketsReducer";
import userReducer from "src/redux/reducers/userReducer";
import { setupListeners } from "@reduxjs/toolkit/query";
import { aikideskApi } from "../api/api";

export const store = configureStore({
  reducer: {
    user: userReducer,
    departments: departmentsReducer,
    tickets: ticketsReducer,
    operators: operatorsReducer,
    cannedMessages: cannedMessagesReducer,
    number: numberReducer,
    preferences: preferencesReducer,
    [aikideskApi.reducerPath]: aikideskApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(aikideskApi.middleware),
});

setupListeners(store.dispatch);
