import React, { useMemo } from "react";
import { ActiveFiltersContainer } from "src/components/ActiveFiltersContainer";
import { useAtom } from "jotai";
import { cannedMessagesAtom, defaultCannedMessagesSearchValues } from "src/components/Searchbar";
import { useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { StyledChip } from "src/components/StyledChip";
import { format } from "date-fns";

export const ActiveCannedMessagesFilters = () => {
  const [filterValues, setFilterValues] = useAtom(cannedMessagesAtom);
  const { operators } = useSelector((state) => state.operators);
  const { user } = useSelector((state) => state.user);

  const creator = useMemo(() => {
    let creator = operators?.filter((item) => item.id === filterValues.createdBy)[0];
    if (creator) {
      if (creator.id === user.id) {
        creator = { ...creator, first_name: user.first_name, last_name: user.last_name };
      }
      if (creator.id === 0) {
        creator = { ...creator, first_name: "Administrator", last_name: "" };
      }
    }
    return creator;
  }, [filterValues.createdBy, user.id, user.first_name, user.last_name, operators]);

  const showClearButton = useMemo(() => {
    return (
      filterValues.createdBy !== defaultCannedMessagesSearchValues.createdBy ||
      filterValues.after !== defaultCannedMessagesSearchValues.after ||
      filterValues.before !== defaultCannedMessagesSearchValues.before
    );
  }, [filterValues, defaultCannedMessagesSearchValues]);

  return (
    <ActiveFiltersContainer>
      {creator && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Created By:{" "}
          </Typography>
          <StyledChip
            label={`${creator.first_name} ${creator.last_name}`}
            onDelete={() => {
              setFilterValues((prev) => ({
                ...prev,
                createdBy: "",
              }));
            }}
          />
        </>
      )}
      {filterValues.after && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Updated From:
          </Typography>
          <StyledChip
            label={format(new Date(filterValues.after), "dd MMM yyyy")}
            onDelete={() => {
              setFilterValues((prev) => ({
                ...prev,
                after: "",
              }));
            }}
          />
        </>
      )}
      {filterValues.before && (
        <>
          <Typography variant="p" sx={{ ml: 1 }}>
            Updated To:
          </Typography>
          <StyledChip
            label={format(new Date(filterValues.before), "dd MMM yyyy")}
            onDelete={() => {
              setFilterValues((prev) => ({
                ...prev,
                before: "",
              }));
            }}
          />
        </>
      )}
      {showClearButton && (
        <Grid>
          <Button onClick={() => setFilterValues(defaultCannedMessagesSearchValues)}>Clear</Button>
        </Grid>
      )}
    </ActiveFiltersContainer>
  );
};
