import axiosInstance from "src/service/axios/axiosInstance";

export const changeTicketsOperatorsRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/tickets/changeOperators",
      data: {
        ids: data.ids,
        action: data.action,
        operators: data.operators,
      },
    });
  } catch (err) {
    return err.response;
  }
};
