import { SET_LOADING, SET_NUMBER } from "src/redux/actionTypes/numberTypes";

export const setNumber = (number) => (dispatch) => {
  dispatch({
    type: SET_NUMBER,
    payload: number,
  });
};

export const setNumberLoading = (state) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: state,
  });
};
