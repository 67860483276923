const statusesId = { Open: [0, 1], "On-hold": 2, Closed: 3, Any: [0, 1, 2, 3] };
export const getParams = (values) => {
  const statusesTemp =
    values.status && values.status.length > 0 ? values.status : ["Open", "On-hold"];
  let statuses = [];
  statusesTemp.forEach((status) => {
    if (!status) {
      return;
    }
    statuses.push(statusesId[status]);
  });

  const version = values.version === "any" ? "" : values.version;

  return {
    ...values,
    status: statuses,
    version,
  };
};
