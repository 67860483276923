import uuid from "react-uuid";

export const addFiles = (preferences, files, newFilesTemp) => {
  const maxFileSize = preferences.max_file_size || 5120;
  const allowedExtensions = preferences.allowed_file_extensions.split(", ");
  let temp = Object.entries(newFilesTemp).map((e) => {
    let newFile = e[1];
    newFile = { file: newFile, id: uuid() };
    return { ...newFile };
  });
  let newFiles = [];
  temp.forEach((file) => {
    if (
      !allowedExtensions.includes(file.file.name.split(".")[file.file.name.split(".").length - 1])
    ) {
      throw new Error(`The type of the file "${file.file.name}" is not allowed!`);
    } else {
      let exists = false;
      files?.forEach((oldFile) => {
        if (oldFile.file.name === file.file.name) {
          exists = true;
        }
      });
      if (file.file.size / 1024 <= maxFileSize) {
        if (!exists) {
          newFiles.push(file);
        }
      } else {
        throw new Error(
          `The file "${file.file.name}" exceeds the ${Math.round(maxFileSize / 1024)}MB limit`
        );
      }
    }
  });

  return [...files, ...newFiles];
};
