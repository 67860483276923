import { SET_PREFERENCES } from "src/redux/actionTypes/preferencesTypes";

const initialState = {
  preferences: null,
  isLoading: true,
};

export default function preferencesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PREFERENCES:
      return {
        preferences: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
