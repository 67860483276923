import React from "react";
import PropTypes from "prop-types";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Auth } from "src/pages/UnAuth/Auth";
import { ForgotPassword } from "src/pages/UnAuth/ForgotPassword";
import { Tickets } from "src/pages/Auth/Tickets";
import { CannedMessages } from "src/pages/Auth/CannedMessages";
import { ChangePassword } from "src/pages/Auth/ChangePassword";
import { Profile } from "src/pages/Auth/Profile";
import { Ticket } from "src/pages/Auth/Ticket";
import { AddTicket } from "src/pages/Auth/AddTicket";
import { CannedMessage } from "src/pages/Auth/CannedMessage";
import { AddCannedMessage } from "src/pages/Auth/AddCannedMessage";
import { Download } from "src/pages/Auth/Download";

const AppRoutes = ({ sidebar }) => {
  const { user } = useSelector((state) => state.user);

  return (
    <Routes>
      {!user ? (
        <>
          <Route exact path="/" element={<Auth />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </>
      ) : (
        <>
          <Route path="/:department">
            <Route path="" element={<Tickets sidebar={sidebar} />} />
          </Route>
          <Route path="/ticket" element={<Ticket />} />
          <Route exact path="/addTicket" element={<AddTicket />} />
          <Route index path="/cannedMessages" element={<CannedMessages sidebar={sidebar} />} />
          <Route
            exact
            path="/cannedMessages/filter"
            element={<CannedMessages sidebar={sidebar} />}
          />
          <Route exact path="/download" element={<Download />} />
          <Route exact path="/cannedMessage" element={<CannedMessage />} />
          <Route exact path="/addCannedMessage" element={<AddCannedMessage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="*" element={<Navigate replace to="/All" />} />
        </>
      )}
    </Routes>
  );
};

AppRoutes.propTypes = {
  sidebar: PropTypes.bool,
  setMessage: PropTypes.func,
};

export { AppRoutes };
