import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { deleteCannedMessageRequest } from "src/api/deleteCannedMessageRequest";
import { style } from "src/styles/modalStyle";
import { array, bool, func } from "prop-types";

const DeleteCannedMessage = ({ open, setOpen, selected, close, enableIcon }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ids: selected,
    };
    await deleteCannedMessageRequest(data);
    if (close) {
      close();
    }
    if (enableIcon) {
      enableIcon();
    }
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          if (enableIcon) {
            enableIcon();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" onSubmit={handleSubmit} sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ alignSelf: "center", fontSize: 28 }}
          >
            Delete Canned {selected.length > 1 ? "Messages" : "Message"}
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            Are you sure you want to delete the selected canned{" "}
            {selected.length > 1 ? "messages" : "message"}?
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: 3 }}>
            <Button
              variant="contained"
              type="button"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                background: "#808080",
                "&:hover": { background: "#686a6b" },
              }}
              onClick={() => {
                setOpen(false);
                if (enableIcon) {
                  enableIcon();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                marginLeft: 2,
                background: "#d13838",
                "&:hover": { background: "#b32020" },
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

DeleteCannedMessage.propTypes = {
  open: bool,
  setOpen: func,
  selected: array,
  close: func,
  enableIcon: func,
};

export { DeleteCannedMessage };
