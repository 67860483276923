import axiosInstance from "src/service/axios/axiosInstance";

export const changePasswordRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/auth/changePassword",
      data: {
        password: data.password,
        newPassword: data.newPassword,
      },
    });
  } catch (err) {
    return err.response;
  }
};
