import axiosInstance from "src/service/axios/axiosInstance";

export const editCannedMessageRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/cannedMessages/editCannedMessage",
      data: {
        id: data.id,
        title: data.title,
        content: data.content,
      },
    });
  } catch (err) {
    return err.response;
  }
};
