import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";

export const Navigation = ({ ticket, tickets }) => {
  const [position, setPosition] = useState(-1);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ticket) {
      return;
    }
    const pos = tickets.findIndex((item) => item.id === ticket.id);

    setPosition(pos);
  }, [ticket, tickets]);

  return (
    <>
      {position > -1 && (
        <Box display="flex" alignItems="center">
          <Typography>
            {position + 1} of {tickets.length}
          </Typography>
          <IconButton
            disabled={position <= 0}
            onClick={() => {
              setPosition((prev) => prev - 1);
              navigate(`/ticket?id=${tickets[position - 1].id}`);
            }}
          >
            <NavigateBeforeIcon fontSize="small" />
          </IconButton>
          <IconButton
            disabled={position >= tickets.length - 1}
            onClick={() => {
              setPosition((prev) => prev + 1);
              navigate(`/ticket?id=${tickets[position + 1].id}`);
            }}
          >
            <NavigateNextIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

Navigation.propTypes = {
  ticket: PropTypes.object,
  tickets: PropTypes.array,
};
