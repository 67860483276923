import { Avatar, Box, Checkbox, IconButton, ListItem, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { returnGravatar } from "src/utils/getGravatar";
import { stringSmallAvatar } from "src/utils/ticketAvatar";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteCannedMessage } from "src/components/DeleteCannedMessage";
import { bool, func, object } from "prop-types";

const WebCard = ({ message, checked, handleSelectChange }) => {
  const [showIcon, setShowIcon] = useState(false);
  const [keepHidden, setKeepHidden] = useState(false);
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();

    if (e.ctrlKey) {
      window.open(
        window.location.origin + `/#/cannedMessage?id=${message.id}`,
        "_blank",
        "noopener,noreferrer"
      );
      return;
    }
    navigate(`/cannedMessage?id=${message.id}`);
  };

  const handleDeleteMessageChange = (newState) => {
    setOpen(newState);
  };

  return (
    <ListItem
      divider
      sx={{
        display: { xs: "none", md: "flex" },
        minWidth: "100%",
        cursor: "pointer",
        alignItems: "center",
        "&:hover": {
          background: "#f7f7f7",
        },
      }}
      onMouseOver={() => {
        if (keepHidden) {
          return;
        }
        setShowIcon(true);
      }}
      onMouseLeave={() => setShowIcon(false)}
    >
      <Box sx={{ width: "40px", paddingRight: "0.5%" }}>
        {message.analyst_id === user.id && (
          <Checkbox checked={checked} onChange={() => handleSelectChange(message.id)} />
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }} onClick={handleClick}>
        <Box sx={{ display: "flex", flexDirection: "row", width: "35px" }}>
          {message.analyst_id === 0 ? (
            <Tooltip title="Administrator">
              <Avatar {...stringSmallAvatar(`Administrator`)} />
            </Tooltip>
          ) : (
            <Tooltip title={`${message.analyst.first_name} ${message.analyst.last_name}`}>
              <Avatar
                {...stringSmallAvatar(`${message.analyst.first_name} ${message.analyst.last_name}`)}
                src={returnGravatar(message.analyst.email)}
                alt={`${message.analyst.first_name} ${message.analyst.last_name}`}
              />
            </Tooltip>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginLeft: 2 }}>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <Box>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "bold",
                }}
              >
                {message.title}
              </Typography>
            </Box>
            <Box sx={{ width: "max-content", display: "flex", justifyContent: "end" }}>
              <Typography variant="p" sx={{ marginLeft: 2, marginRight: "30px" }}>
                {format(new Date(message.ts_updated * 1000), "dd MMM yyyy HH:mm")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "100%", overflow: "hidden", maxHeight: "27px", display: "flex" }}>
            <Typography>
              {message.content.substring(0, 80)}
              {message.content.length > 80 ? "..." : ""}
            </Typography>
          </Box>
        </Box>
      </Box>
      {showIcon && message.analyst_id === user.id && (
        <Tooltip title="Delete">
          <IconButton
            sx={{ position: "absolute", right: 5 }}
            onClick={() => {
              setOpen(true);
              setShowIcon(false);
              setKeepHidden(true);
            }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        </Tooltip>
      )}
      <DeleteCannedMessage
        open={open}
        setOpen={handleDeleteMessageChange}
        selected={[message.id]}
        enableIcon={() => setKeepHidden(false)}
      />
    </ListItem>
  );
};

WebCard.propTypes = {
  message: object,
  checked: bool,
  handleSelectChange: func,
};

export { WebCard };
