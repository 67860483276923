import {
  ADD_CANNED_MESSAGES,
  DELETE_CANNED_MESSAGES,
  SET_CANNED_MESSAGES,
  UPDATE_CANNED_MESSAGES,
} from "src/redux/actionTypes/cannedMessagesTypes";

export const setCannedMessages = (cannedMessages) => (dispatch) => {
  dispatch({
    type: SET_CANNED_MESSAGES,
    payload: cannedMessages,
  });
};

export const addCannedMessage = (cannedMessage) => (dispatch) => {
  dispatch({
    type: ADD_CANNED_MESSAGES,
    payload: cannedMessage,
  });
};

export const updateCannedMessages = (cannedMessage) => (dispatch) => {
  dispatch({
    type: UPDATE_CANNED_MESSAGES,
    payload: cannedMessage,
  });
};

export const deleteCannedMessages = (cannedMessages) => (dispatch) => {
  dispatch({
    type: DELETE_CANNED_MESSAGES,
    payload: cannedMessages,
  });
};
