import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { array, func, string } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getCustomersRequest } from "src/api/getCustomersRequest";
import { stringAvatar } from "src/utils/createAvatar";
import { returnGravatar } from "src/utils/getGravatar";
import { instances } from "src/helpers/instances";

const versions = [
  { label: "LV1", value: "1" },
  { label: "LV2", value: "2" },
];

const TicketsSearch = ({
  setKeepOpen,
  clearForm,
  submit,
  from,
  setFrom,
  department,
  setDepartment,
  assignee,
  setAssignee,
  after,
  setAfter,
  before,
  setBefore,
  status,
  setStatus,
  version,
  setVersion,
}) => {
  const { departments } = useSelector((state) => state.departments);
  const { operators } = useSelector((state) => state.operators);
  const { user } = useSelector((state) => state.user);
  const [statuses] = useState(["Any", "Open", "On-hold", "Closed"]);
  const [customersTemp, setCustomersTemp] = useState([]);
  const [prev, setPrev] = useState("");
  const [operatorsTemp, setOperatorsTemp] = useState(operators);

  const showVersion = useMemo(() => {
    return instances[window.location.origin] === "lv";
  }, [instances]);

  useEffect(() => {
    if (!operators) {
      return;
    }
    setOperatorsTemp(operators);
  }, [operators]);

  const handleFromChange = (e, values) => {
    if (typeof values[values.length - 1] === "string") {
      return;
    }
    setFrom(values);
  };

  const changeDepartment = (e) => {
    setDepartment(e.target.value);
  };

  const changeFromDate = (e) => {
    setAfter(e.target.value);
  };

  const changeToDate = (e) => {
    setBefore(e.target.value);
  };

  const changeVersion = (e) => {
    setVersion(e.target.value);
  };

  const handleInputChange = async (e) => {
    if (e.target.value === undefined || e.target.value.length < 3 || e.target.value === prev) {
      setPrev(e.target.value);
      if (customersTemp.length > 0) {
        setCustomersTemp([]);
      }
      return;
    }
    setPrev(e.target.value);
    const response = await getCustomersRequest(e.target.value);
    if (response.status === 200) {
      setCustomersTemp(response.data.customers);
    }
  };

  const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const processChange = debounce((e) => handleInputChange(e));

  const handleAssigneeChange = (e, values) => {
    setOperatorsTemp(operators);
    setAssignee(values);
  };

  const handleStatusesChange = (e, values) => {
    setStatus(values);
  };

  const handleAssigneeInputChange = (e) => {
    if (!e.target.value) {
      setOperatorsTemp(operators);
      return;
    }
    const newOptions = operators.filter((option) => {
      const name = `${option.first_name} ${option.last_name}`.toLowerCase();
      if (
        name.includes(e.target.value.toLowerCase()) ||
        option.email.includes(e.target.value.toLowerCase())
      ) {
        return option;
      } else return null;
    });
    setOperatorsTemp(newOptions);
  };

  return (
    <form onSubmit={submit}>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={12} md={2}>
          <Typography>From</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Autocomplete
            multiple
            id="from"
            options={customersTemp || []}
            getOptionLabel={(option) => option.email}
            value={from || []}
            filterOptions={(options) => {
              return options;
            }}
            autoHighlight={true}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.email === value.email}
            freeSolo
            onChange={handleFromChange}
            onInputChange={processChange}
            onFocus={() => setKeepOpen(true)}
            onBlur={() => {
              setKeepOpen(false);
              setCustomersTemp([]);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                {...props}
                key={option.id}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <Avatar
                    {...stringAvatar(
                      option.last_name ? `${option.first_name} ${option.last_name}` : option.email
                    )}
                    src={returnGravatar(option.email)}
                    alt={
                      option.last_name ? `${option.first_name} ${option.last_name}` : option.email
                    }
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", marginLeft: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {option.first_name} {option.last_name}
                  </Typography>
                  <Typography variant="p">{option.email}</Typography>
                </Box>
              </Box>
            )}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <Typography>Department</Typography>
        </Grid>
        <Grid item xs={12} md={10} sx={{ marginTop: { xs: 0, md: 2 } }}>
          <TextField
            select
            id="department"
            sx={{ width: "100%" }}
            value={department}
            onChange={changeDepartment}
            onFocus={() => setKeepOpen(true)}
            onBlur={() => setKeepOpen(false)}
          >
            <MenuItem value="All">All</MenuItem>
            {departments.map((department) => {
              if (
                !user.departments.filter((dep) => dep.department_id === department.id)[0] &&
                department.id !== 1
              ) {
                return <div key={department.id}></div>;
              }
              return (
                <MenuItem key={department.id} value={department.title.replaceAll(" ", "")}>
                  {department.title}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} md={2} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <Typography>Assignee</Typography>
        </Grid>
        <Grid item xs={12} md={10} sx={{ marginTop: { xs: 0, md: 2 } }}>
          <Autocomplete
            multiple
            id="assignee"
            options={operatorsTemp || []}
            filterSelectedOptions
            freeSolo
            getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
            autoHighlight={true}
            value={assignee || []}
            onChange={handleAssigneeChange}
            onFocus={() => {
              setKeepOpen(true);
              setOperatorsTemp(operators);
            }}
            onBlur={() => setKeepOpen(false)}
            onInputChange={handleAssigneeInputChange}
            filterOptions={(options) => {
              if (assignee.length > 0 && assignee[0].first_name === "Unassigned") {
                return options.filter((option) => option.first_name === "Unassigned");
              }
              if (assignee.length > 0 && assignee[0].first_name !== "Unassigned") {
                return options.filter((option) => option.first_name !== "Unassigned");
              }
              return options;
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        {showVersion && (
          <>
            <Grid item xs={12} md={2} sx={{ marginTop: { xs: 1, md: 2 } }}>
              <Typography>Version</Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={{ marginTop: { xs: 0, md: 2 } }}>
              <TextField
                select
                id="department"
                sx={{ width: "100%" }}
                value={version}
                onChange={changeVersion}
                onFocus={() => setKeepOpen(true)}
                onBlur={() => setKeepOpen(false)}
              >
                <MenuItem value="any">Any</MenuItem>
                {versions.map((version) => {
                  return (
                    <MenuItem key={version.value} value={version.value}>
                      {version.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </>
        )}
        <Grid item xs={6} md={2} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <Typography>Updated From</Typography>
        </Grid>
        <Grid item xs={6} md={4} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <TextField
            type="date"
            id="fromDate"
            value={after}
            onChange={changeFromDate}
            InputProps={{ inputProps: { max: before ? before : format(new Date(), "yyyy-MM-dd") } }}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          sx={{ marginTop: { xs: 1, md: 2 }, paddingLeft: { xs: 0, md: 2 } }}
        >
          <Typography>To</Typography>
        </Grid>
        <Grid item xs={6} md={4} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <TextField
            type="date"
            id="to"
            value={before}
            onChange={changeToDate}
            InputProps={{
              inputProps: { min: after && after, max: format(new Date(), "yyyy-MM-dd") },
            }}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <Typography>Status</Typography>
        </Grid>
        <Grid item xs={12} md={10} sx={{ marginTop: { xs: 1, md: 2 } }}>
          <Autocomplete
            multiple
            id="status"
            options={statuses || []}
            filterSelectedOptions
            value={status.filter((status) => !!status) || []}
            onChange={handleStatusesChange}
            onFocus={() => setKeepOpen(true)}
            onBlur={() => setKeepOpen(false)}
            onKeyDown={(e) => e.preventDefault()}
            sx={{ caretColor: "transparent" }}
            filterOptions={(options) => {
              if (status.length > 0 && status.includes("Any")) {
                return options.filter((option) => option === "Any");
              }
              if (status.length > 0 && !status.includes("Any")) {
                return options.filter((option) => option !== "Any");
              }
              return options;
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginTop: 4, display: "flex", justifyContent: "flex-end", marginBottom: 2 }}
        >
          <Button
            sx={{ marginRight: 2, textTransform: "none" }}
            type="button"
            onClick={() => clearForm()}
          >
            Reset
          </Button>
          <Button sx={{ textTransform: "none" }} variant="contained" type="submit">
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

TicketsSearch.propTypes = {
  setKeepOpen: func,
  clearForm: func,
  submit: func,
  from: array,
  setFrom: func,
  department: string,
  setDepartment: func,
  assignee: array,
  setAssignee: func,
  after: string,
  setAfter: func,
  before: string,
  setBefore: func,
  status: array,
  setStatus: func,
  version: string,
  setVersion: func,
};

export { TicketsSearch };
