import axiosInstance from "src/service/axios/axiosInstance";

export const addTimeLogRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/tickets/addTimeLog",
      data: {
        ticket_id: data.ticket_id,
        date: data.date,
        time: data.time,
      },
    });
  } catch (err) {
    return err.response;
  }
};
