import { Avatar, Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { stringAvatar } from "src/utils/createAvatar";
import { returnGravatar } from "src/utils/getGravatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { logout } from "src/api/logout";
import { instances } from "src/helpers/instances";

const ProfileDropdown = () => {
  const { user } = useSelector((state) => state.user);
  const [avatar, setAvatar] = useState("");
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const resp = returnGravatar(user.email);
    setAvatar(resp);
  }, [user]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTimeout(() => {
          setOpen();
        }, 90);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, setOpen]);

  const handleLogout = async () => {
    const instance = instances[window.location.origin];
    const response = await logout();
    localStorage.removeItem(`${instance}_refresh_key`);
    Cookies.remove(`${instance}_access_key`);
    if (response.status === 204) {
      window.location.href = "/";
    }
  };

  return (
    <Box sx={{ userSelect: "none" }}>
      <Box
        sx={{
          background: open ? "#f6f6f6" : "#fcfcfc",
          boxShadow: 1,
          padding: "10px",
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": { background: "#f3f3f3" },
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Box sx={{ marginLeft: "10px" }}>
          <Avatar {...stringAvatar(`${user.first_name} ${user.last_name}`)} src={avatar} />
        </Box>
        <Typography variant="h6" sx={{ marginLeft: "15px", fontSize: 17 }}>
          {user.first_name} {user.last_name}
        </Typography>
        {open ? (
          <ExpandLessIcon sx={{ fontSize: 20, marginLeft: 1 }} />
        ) : (
          <ExpandMoreIcon sx={{ fontSize: 20, marginLeft: 1 }} />
        )}
      </Box>
      {open && (
        <Box
          ref={wrapperRef}
          sx={{
            position: "absolute",
            minWidth: 250,
            background: "#fcfcfc",
            right: 40,
            top: "75px",
            borderRadius: 5,
            boxShadow: 2,
          }}
        >
          <Box sx={{ margin: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
                "&:hover": { background: "#f3f3f3" },
                padding: 0.5,
                borderRadius: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/profile");
                setOpen(false);
              }}
            >
              <AccountCircleIcon sx={{ color: "#424242", fontSize: 30 }} />
              <Typography variant="p" sx={{ fontSize: 17, marginLeft: 2 }}>
                Profile
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
                marginTop: 1,
                "&:hover": { background: "#f3f3f3" },
                padding: 0.5,
                borderRadius: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/changePassword");
                setOpen(false);
              }}
            >
              <LockIcon sx={{ color: "#424242", fontSize: 30 }} />
              <Typography variant="p" sx={{ fontSize: 17, marginLeft: 2 }}>
                Change Password
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 1,
                "&:hover": { background: "#f3f3f3" },
                padding: 0.5,
                borderRadius: 2,
                cursor: "pointer",
              }}
              onClick={handleLogout}
            >
              <LogoutIcon sx={{ color: "#424242", fontSize: 30 }} />
              <Typography variant="p" sx={{ fontSize: 17, marginLeft: 2 }}>
                Log Out
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export { ProfileDropdown };
