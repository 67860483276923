import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";

export const ActiveFiltersContainer = ({ children }) => {
  return (
    <Stack
      display={{ xs: "none", md: "flex" }}
      marginX={{ xs: 3, md: 5 }}
      direction="row"
      spacing={1}
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
    >
      {children}
    </Stack>
  );
};

ActiveFiltersContainer.propTypes = {
  children: PropTypes.node,
};
