import { bool, node, string } from "prop-types";
import { alpha, Badge, Box, Typography } from "@mui/material";
import React from "react";

const Content = ({ badgeContent }) => {
  return (
    <Box
      sx={{
        backgroundColor: alpha("#00A36F", 1),
        height: { xs: "16px", md: "18px" },
        minWidth: { xs: "16px", md: "18px" },
        borderRadius: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: 3,
        px: 0.5,
        mt: -0.6,
        ml: 2,
      }}
    >
      <Typography sx={{ fontWeight: "bold", color: "white", fontSize: { xs: "12px", md: "14px" } }}>
        {badgeContent}
      </Typography>
    </Box>
  );
};
export const VersionBadge = ({ badgeContent, children, showVersion }) => {
  if (!showVersion) {
    return <>{children}</>;
  }

  return (
    <Badge
      badgeContent={<Content badgeContent={badgeContent} />}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {children}
    </Badge>
  );
};

VersionBadge.propTypes = {
  badgeContent: string,
  children: node,
  showVersion: bool,
};

Content.propTypes = {
  badgeContent: string,
};
