import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { format, isToday } from "date-fns";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import MailLockRoundedIcon from "@mui/icons-material/MailLockRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ActivityHistory } from "src/components/ActivityHistory";
import { EditTimeLog } from "src/components/EditTimeLog";
import { Reminders } from "src/components/Reminders";
import { ViewCompleteLog } from "src/components/ViewCompleteLog";
import { stringSmallAvatar } from "src/utils/ticketAvatar";
import { returnGravatar } from "src/utils/getGravatar";
import { TicketPriorityChange } from "src/components/TicketPriorityChange";
import { TicketDepartmentChange } from "src/components/TicketDepartmentChange";
import { TicketStatusChange } from "src/components/TicketStatusChange";
import { ChangeTicketOperators } from "src/components/MobileChangeTicketOperators";
import { EmailPopup } from "src/components/EmailPopup";
import { AddEmail } from "src/components/AddEmail";
import { useRef } from "react";
import { removeEmailRequest } from "src/api/removeEmailRequest";
import { array, bool, func, object } from "prop-types";
import snackbarUtils from "../../utils/SnackbarUtils";
import { useAtom } from "jotai";
import { ticketsAtom } from "src/components/Searchbar";
import { useNavigate } from "react-router-dom";
import { VersionBadge } from "src/components/VersionBadge";

const MobileHeader = ({ ticket, emails, openReply, openInternalMessage, showVersion }) => {
  const [open, setOpen] = useState(false);
  const [openEmailOptions, setOpenEmailOptions] = useState(-1);
  const [myTime, setMyTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [keepOpen, setKeepOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const { user } = useSelector((state) => state.user);
  const emailRef = useRef(null);
  const widthRef = useRef(null);
  const [, setFilterValues] = useAtom(ticketsAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (emails.length === 0) {
      return;
    }
    let ccTemp = [];
    let bccTemp = [];
    emails.forEach((element) => {
      if (element.email !== ticket.user.email) {
        if (element.analyst_email) {
          bccTemp.push(element);
        } else {
          ccTemp.push(element);
        }
      }
    });
    setBcc(bccTemp);
    setCc(ccTemp);
  }, [emails, ticket]);

  const getTimes = useCallback(() => {
    let myTimeTemp = 0,
      totalTimeTemp = 0;

    ticket.timeLogs.forEach((log) => {
      if (log.analyst_id === user.id) {
        myTimeTemp += log.total_time;
      }
      totalTimeTemp += log.total_time;
    });
    setMyTime(myTimeTemp);
    setTotalTime(totalTimeTemp);
  }, [ticket, user]);

  useEffect(() => {
    if (!ticket) {
      return;
    }
    getTimes();
  }, [ticket, getTimes]);

  useEffect(() => {
    setWidth((widthRef?.current?.offsetWidth / 3) * 2.2);
  }, [widthRef]);

  useEffect(() => {
    window.onresize = () => {
      handleResize();
    };
  }, []);

  const handleResize = () => {
    setWidth((widthRef?.current?.offsetWidth / 3) * 2.2);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (emailRef.current && !emailRef.current.contains(event.target)) {
        setOpenEmailOptions(-1);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emailRef]);

  const handleDelete = async (id) => {
    const data = {
      ticket_id: ticket.id,
      id,
    };
    await removeEmailRequest(data);
  };

  const handleKeepOpen = (state) => {
    if (state === keepOpen) {
      return;
    }
    setKeepOpen(state);
  };

  return (
    <Box
      sx={{
        background: "#f7f7f7",
        p: 1.5,
        borderRadius: 2,
        display: { xs: "flex", md: "none" },
        flexDirection: "column",
      }}
    >
      <Box
        ref={widthRef}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <VersionBadge showVersion={showVersion && ticket.version === 2} badgeContent="LV2">
            <Typography
              variant="p"
              sx={{ fontSize: "15px", fontWeight: "bold", marginBottom: 0.5 }}
            >
              &#91;{ticket?.ticket_number}&#93;
            </Typography>
          </VersionBadge>
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            onClick={() => setOpen((prev) => !prev)}
          >
            <AvatarGroup
              sx={{
                marginLeft: 1,
                "& .MuiAvatar-root": {
                  width: 24,
                  height: 24,
                  fontSize: 13,
                },
              }}
              max={5}
            >
              {ticket.assignees.map((assignee) => (
                <Avatar
                  key={assignee.id}
                  {...stringSmallAvatar(
                    `${assignee.analyst.first_name} ${assignee.analyst.last_name}`
                  )}
                  src={returnGravatar(assignee.analyst.email)}
                />
              ))}
            </AvatarGroup>
            <ChangeTicketOperators assignees={ticket.assignees} ticket={ticket} />
          </Box>
        </Box>
        <Box>
          <Typography
            variant="p"
            sx={{
              fontSize: "12px",
              minWidth: "40px",
              textAlign: "end",
            }}
          >
            {ticket?.ts_updated_by_analyst > ticket?.ts_updated_by_customer
              ? isToday(new Date(ticket?.ts_updated_by_analyst * 1000))
                ? format(new Date(ticket?.ts_updated_by_analyst * 1000), "HH:mm")
                : format(new Date(ticket?.ts_updated_by_analyst * 1000), "yyyy") <
                  format(new Date(), "yyyy")
                ? format(new Date(ticket?.ts_updated_by_analyst * 1000), "MMM `yy")
                : format(new Date(ticket?.ts_updated_by_analyst * 1000), "dd MMM")
              : isToday(new Date(ticket?.ts_updated_by_customer * 1000))
              ? format(new Date(ticket?.ts_updated_by_customer * 1000), "HH:mm")
              : format(new Date(ticket.ts_updated_by_analyst * 1000), "yyyy") <
                format(new Date(), "yyyy")
              ? format(new Date(ticket?.ts_updated_by_customer * 1000), "MMM `yy")
              : format(new Date(ticket?.ts_updated_by_customer * 1000), "dd MMM")}
          </Typography>
          {!open ? (
            <IconButton sx={{ width: 20, height: 20, ml: 1 }}>
              <ExpandMoreRoundedIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton sx={{ width: 20, height: 20, ml: 1 }}>
              <ExpandLessRoundedIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          variant="p"
          sx={{ fontSize: "16px", fontWeight: "bold" }}
          onClick={() => setOpen((prev) => !prev)}
        >
          {ticket.subject}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ActivityHistory id={ticket.id} customer={ticket.user} />
          {ticket.status_id < 3 && (
            <>
              <IconButton onClick={openInternalMessage} sx={{ width: 30, height: 30 }}>
                <MailLockRoundedIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={openReply} sx={{ width: 30, height: 30 }}>
                <ReplyRoundedIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      <Collapse in={open} sx={{ fontSize: 14 }} timeout={{ enter: 100, exit: 100 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: 1, width: "max-content" }}
          >
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        verticalAlign: "top",
                        paddingTop: 0.2,
                      }}
                    >
                      <Typography variant="p">From</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        paddingLeft: 2,
                        width: width,
                        fontWeight: "normal",
                      }}
                    >
                      <Box sx={{ marginBottom: 1, maxWidth: width }}>
                        <Button
                          sx={{
                            textTransform: "none",
                            fontSize: 14,
                            p: 0,
                            px: 1,
                            fontWeight: "normal",
                            textAlign: "left",
                          }}
                          onClick={() => setOpenEmailOptions(-2)}
                        >
                          {ticket.user.email}
                        </Button>
                        {openEmailOptions === -2 && (
                          <Box ref={emailRef}>
                            <EmailPopup>
                              <Button
                                sx={{ textTransform: "none", width: "100%" }}
                                onClick={() => {
                                  snackbarUtils.success("Copied Successfully");
                                  navigator.clipboard.writeText(ticket.user.email);
                                  setOpenEmailOptions(false);
                                }}
                              >
                                Copy Email Address
                              </Button>
                              <Button
                                sx={{
                                  textTransform: "none",
                                  width: "100%",
                                  justifyContent: "start",
                                }}
                                target="_top"
                                rel="noopener noreferrer"
                                href={`mailto:${ticket.user.email}`}
                              >
                                Send Email
                              </Button>
                              <Button
                                sx={{
                                  textTransform: "none",
                                  width: "100%",
                                  justifyContent: "start",
                                }}
                                onClick={() => {
                                  setFilterValues((prev) => ({
                                    ...prev,
                                    from: [ticket.user.email],
                                    status: ["Any"],
                                  }));
                                  navigate("/All");
                                }}
                              >
                                View Tickets
                              </Button>
                            </EmailPopup>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  {cc.length > 0 && (
                    <TableRow>
                      <TableCell
                        sx={{
                          border: "none",
                          p: 0,
                          verticalAlign: "top",
                        }}
                      >
                        <Typography variant="p">Cc</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          p: 0,
                          paddingLeft: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "end",
                            width: width,
                            justifyContent: "start",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                            }}
                          >
                            {cc.map((element) => (
                              <Box
                                key={element.id}
                                sx={{
                                  marginLeft: 1,
                                  marginBottom: 1,
                                  maxWidth: width - 10,
                                }}
                              >
                                <Chip
                                  sx={{ overflow: "hidden" }}
                                  label={element.email}
                                  onClick={() => setOpenEmailOptions(element.id)}
                                  onDelete={() => handleDelete(element.id)}
                                />
                                {openEmailOptions === element.id && (
                                  <Box ref={emailRef}>
                                    <EmailPopup>
                                      <Button
                                        sx={{ textTransform: "none", width: "100%" }}
                                        onClick={() => {
                                          snackbarUtils.success("Copied Successfully");
                                          navigator.clipboard.writeText(element.email);
                                          setOpenEmailOptions(false);
                                        }}
                                      >
                                        Copy Email Address
                                      </Button>
                                      <Button
                                        sx={{
                                          textTransform: "none",
                                          width: "100%",
                                          justifyContent: "start",
                                        }}
                                        target="_top"
                                        rel="noopener noreferrer"
                                        href={`mailto:${element.email}`}
                                      >
                                        Send Email
                                      </Button>
                                      <AddEmail defaultChecked={false} ticket_id={ticket.id} />
                                    </EmailPopup>
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {bcc.length > 0 && (
                    <TableRow>
                      <TableCell
                        sx={{
                          border: "none",
                          p: 0,
                          verticalAlign: "top",
                        }}
                      >
                        <Typography variant="p">Bcc</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          p: 0,
                          paddingLeft: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "end",
                            width: width,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                            }}
                          >
                            {bcc.map((element) => (
                              <Box
                                key={element.id}
                                sx={{
                                  marginLeft: 1,
                                  marginBottom: 1,
                                  maxWidth: width - 10,
                                }}
                              >
                                <Chip
                                  sx={{ overflow: "hidden" }}
                                  label={element.email}
                                  onClick={() => setOpenEmailOptions(element.id)}
                                  onDelete={() => handleDelete(element.id)}
                                />
                                {openEmailOptions === element.id && (
                                  <Box ref={emailRef}>
                                    <EmailPopup>
                                      <Button
                                        sx={{ textTransform: "none", width: "100%" }}
                                        onClick={() => {
                                          snackbarUtils.success("Copied Successfully");
                                          navigator.clipboard.writeText(element.email);
                                          setOpenEmailOptions(false);
                                        }}
                                      >
                                        Copy Email Address
                                      </Button>
                                      <Button
                                        sx={{
                                          textTransform: "none",
                                          width: "100%",
                                          justifyContent: "start",
                                        }}
                                        target="_top"
                                        rel="noopener noreferrer"
                                        href={`mailto:${element.email}`}
                                      >
                                        Send Email
                                      </Button>
                                      <AddEmail defaultChecked={true} ticket_id={ticket.id} />
                                    </EmailPopup>
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {((bcc.length === 0 && cc.length === 0) || keepOpen) && (
                    <TableRow>
                      <TableCell
                        sx={{
                          border: "none",
                          p: 0,
                        }}
                      >
                        <Typography variant="p">Cc</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          p: 0,
                          paddingLeft: 2,
                        }}
                      >
                        <AddEmail
                          defaultChecked={false}
                          ticket_id={ticket.id}
                          setKeepOpen={handleKeepOpen}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                      }}
                    >
                      <Typography variant="p">Reminder</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        paddingLeft: 2,
                      }}
                    >
                      <Reminders reminders={ticket.reminders} ticket_id={ticket.id} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                      }}
                    >
                      <Typography variant="p">My Time</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        paddingLeft: 2,
                      }}
                    >
                      <EditTimeLog
                        timeLogs={ticket.timeLogs.filter((log) => log.analyst_id === user.id)}
                        ticket_id={ticket.id}
                        analyst_id={user.id}
                        myTime={myTime}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                      }}
                    >
                      <Typography variant="p">Total Time</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        p: 0,
                        paddingLeft: 2,
                      }}
                    >
                      <ViewCompleteLog timeLogs={ticket.timeLogs} totalTime={totalTime} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ display: "flex", marginBottom: 1 }}>
            <TicketPriorityChange priority={ticket.priority_id} id={ticket.id} halfWidth={true} />
            <TicketStatusChange status={ticket.status_id} id={ticket.id} halfWidth={true} />
          </Box>
          <TicketDepartmentChange
            department={ticket.department_id}
            id={ticket.id}
            fullWidth={true}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

MobileHeader.propTypes = {
  ticket: object,
  emails: array,
  openReply: func,
  openInternalMessage: func,
  showVersion: bool,
};

export { MobileHeader };
