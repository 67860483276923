import {
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { style } from "src/styles/modalStyle";

const ViewCompleteLog = ({ timeLogs, totalTime }) => {
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { operators } = useSelector((state) => state.operators);

  return (
    <>
      {totalTime <= 0 ? (
        <Typography sx={{ fontSize: 14, pl: 1 }}>00 h 00 m</Typography>
      ) : (
        <Button
          sx={{ textTransform: "none", fontSize: 14, p: 0, px: 1, fontWeight: "normal" }}
          onClick={() => setOpen(true)}
        >
          {Math.floor(totalTime / 3600) < 10
            ? `0${Math.floor(totalTime / 3600)}`
            : Math.floor(totalTime / 3600)}{" "}
          h{" "}
          {Math.floor((totalTime % 3600) / 60) < 10
            ? `0${Math.floor((totalTime % 3600) / 60)}`
            : Math.floor((totalTime % 3600) / 60)}{" "}
          m
        </Button>
      )}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, background: "white" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Complete Time Log</Typography>
            <Tooltip title="Close">
              <IconButton sx={{ marginRight: -2, marginTop: -1 }} onClick={() => setOpen(false)}>
                <CloseRoundedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: "bold" }}>Operator</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: "bold" }}>Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: "bold" }}>Time</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeLogs
                  .sort((a, b) => {
                    return a.ts_date - b.ts_date;
                  })
                  .map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>
                        <Typography>
                          {log.analyst_id === user?.id
                            ? `${user.first_name} ${user.last_name}`
                            : `${
                                operators?.filter((op) => op.id === log.analyst_id)[0].first_name
                              } ${
                                operators?.filter((op) => op.id === log.analyst_id)[0].last_name
                              }`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {format(new Date(log.ts_date * 1000), "dd MMM yyyy HH:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {Math.floor(log.total_time / 3600) < 10
                            ? `0${Math.floor(log.total_time / 3600)}`
                            : Math.floor(log.total_time / 3600)}{" "}
                          h{" "}
                          {Math.floor((log.total_time % 3600) / 60) < 10
                            ? `0${Math.floor((log.total_time % 3600) / 60)}`
                            : Math.floor((log.total_time % 3600) / 60)}{" "}
                          m{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
};

ViewCompleteLog.propTypes = {
  timeLogs: PropTypes.array,
  totalTime: PropTypes.number,
};

export { ViewCompleteLog };
