import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MobileCard } from "src/components/TicketsForm/MobileCard";
import { WebCard } from "src/components/TicketsForm/WebCard";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChangePriority } from "src/components/ChangePriority";
import { ChangeDepartment } from "src/components/ChangeDepartment";
import { ChangeOperators } from "src/components/ChangeOperators";
import { CloseTickets } from "src/components/CloseTickets";
import { SetOpen } from "src/components/SetOpen";
import { SetOnHold } from "src/components/SetOnHold";
import { array } from "prop-types";

const TicketsForm = ({ tickets }) => {
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [length, setLength] = useState(0);
  const location = useLocation();
  const wrapperRef = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    setSelected([]);
    setOpen(false);
  }, [location]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTimeout(() => {
          if (showMore) {
            setShowMore(false);
          }
        }, 250);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, showMore]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setTimeout(() => {
          if (openOptions) {
            setOpenOptions(false);
          }
        }, 250);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsRef, openOptions]);

  const handleSelectChange = (id) => {
    const temp = selected;
    const index = temp.indexOf(id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(id);
    }
    setSelected(temp);
    if (selected.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setLength(selected.length);
  };

  const handleSelectAll = () => {
    const temp = [];
    tickets.forEach((element) => {
      temp.push(element.id);
    });
    setSelected(temp);
    setLength(temp.length);
  };

  const handleDeselectAll = () => {
    setSelected([]);
    setOpen(false);
  };

  return (
    <Box
      id="form"
      sx={{ marginBottom: open && { xs: "40px", md: "20px" }, marginTop: 1, width: "100%" }}
    >
      {tickets.length > 0 &&
        tickets?.map((ticket) => {
          return (
            <Box key={ticket.id}>
              <WebCard
                ticket={ticket}
                handleSelectChange={handleSelectChange}
                checked={selected.includes(ticket.id)}
                selected={selected}
              />
              <MobileCard
                ticket={ticket}
                handleSelectChange={handleSelectChange}
                checked={selected.includes(ticket.id)}
                selected={Boolean(selected.length > 0)}
              />
            </Box>
          );
        })}
      {open && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100vw",
            height: { xs: "fit-content", md: "70px" },
            padding: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#f7f7f7",
            zIndex: 101,
            paddingBottom: { xs: 2, md: 1 },
            boxShadow: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { xs: "97%", md: "fit-content" },
              justifyContent: { xs: "space-between", md: "center" },
              paddingLeft: { xs: 1, md: 10 },
              paddingRight: { xs: 4, md: 0 },
            }}
          >
            <Box
              sx={{
                width: { xs: "100px", md: "110px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Typography sx={{ fontSize: 18, marginLeft: { xs: 1, md: 2 } }}>
                {length} selected
              </Typography>
            </Box>
            {selected.length < tickets.length ? (
              <Box>
                <Tooltip title="Deselect All">
                  <IconButton onClick={handleDeselectAll}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Select All">
                  <IconButton onClick={handleSelectAll} sx={{ marginRight: 2 }}>
                    <CheckRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Box>
                <Tooltip title="Deselect All">
                  <IconButton onClick={handleDeselectAll}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Select All">
                  <span>
                    <IconButton disabled sx={{ marginRight: 2 }}>
                      <CheckRoundedIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            >
              <CloseTickets selected={selected} />
              <IconButton sx={{ marginLeft: 1 }} onClick={() => setOpenOptions(true)}>
                <MoreVertIcon />
              </IconButton>
              <Box
                ref={optionsRef}
                sx={{
                  background: "#fcfcfc",
                  position: "absolute",
                  display: openOptions ? "block" : "none",
                  right: "20px",
                  bottom: "70px",
                  boxShadow: 3,
                  borderRadius: 4,
                  zIndex: 20000,
                }}
              >
                <Box
                  sx={{
                    mt: 1,
                    transition: "background, 0.2s",
                    "&:hover": { background: "#e6e6e6" },
                  }}
                >
                  <SetOpen
                    selected={selected}
                    close={() => setOpenOptions(false)}
                    closeBar={handleDeselectAll}
                    small={true}
                  />
                </Box>
                <Box
                  sx={{
                    transition: "background, 0.2s",
                    "&:hover": { background: "#e6e6e6" },
                  }}
                >
                  <SetOnHold
                    selected={selected}
                    close={() => setOpenOptions(false)}
                    closeBar={handleDeselectAll}
                    small={true}
                  />
                </Box>
                <Box
                  sx={{
                    transition: "background, 0.2s",
                    "&:hover": { background: "#e6e6e6" },
                  }}
                >
                  <ChangePriority
                    selected={selected}
                    close={() => {
                      setOpenOptions(false);
                    }}
                    closeBar={handleDeselectAll}
                    small={true}
                  />
                </Box>
                <Box
                  sx={{
                    transition: "background, 0.2s",
                    "&:hover": { background: "#e6e6e6" },
                  }}
                >
                  <ChangeDepartment
                    selected={selected}
                    close={() => {
                      setOpenOptions(false);
                    }}
                    closeBar={handleDeselectAll}
                    small={true}
                  />
                </Box>
                <Box
                  sx={{
                    mb: 1,
                    transition: "background, 0.2s",
                    "&:hover": { background: "#e6e6e6" },
                  }}
                >
                  <ChangeOperators
                    selected={selected}
                    close={() => {
                      setOpenOptions(false);
                    }}
                    closeBar={handleDeselectAll}
                    small={true}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <CloseTickets selected={selected} closeBar={handleDeselectAll} />
            <ChangePriority selected={selected} closeBar={handleDeselectAll} />
            <ChangeDepartment selected={selected} closeBar={handleDeselectAll} />
            <ChangeOperators selected={selected} closeBar={handleDeselectAll} />
            <Tooltip title="Show more">
              <IconButton
                onClick={() => setShowMore((prev) => !prev)}
                sx={{ width: 40, height: 40 }}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Box>
              <Box
                ref={wrapperRef}
                sx={{
                  background: "#fcfcfc",
                  position: "absolute",
                  display: showMore ? "block" : "none",
                  boxShadow: "3",
                  bottom: 10,
                  borderRadius: 3,
                }}
              >
                <Box
                  sx={{
                    mt: 1,
                    transition: "background, 0.2s",
                    "&:hover": { background: "#e6e6e6" },
                  }}
                >
                  <SetOpen
                    selected={selected}
                    close={() => setShowMore(false)}
                    closeBar={handleDeselectAll}
                  />
                </Box>
                <Box
                  sx={{
                    mb: 1,
                    transition: "background, 0.2s",
                    "&:hover": { background: "#e6e6e6" },
                  }}
                >
                  <SetOnHold
                    selected={selected}
                    close={() => setShowMore(false)}
                    closeBar={handleDeselectAll}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

TicketsForm.propTypes = {
  tickets: array,
};

export { TicketsForm };
