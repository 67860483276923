import { Box, Button, Modal, Typography } from "@mui/material";
import { array, func } from "prop-types";
import React, { useState } from "react";
import { changeTicketsStatusRequest } from "src/api/changeTicketsStatusRequest";
import { style } from "src/styles/modalStyle";

const SetOpen = ({ selected, close, setKeepOpen = () => {}, enableIcon, closeBar }) => {
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ids: selected,
      status: "1",
    };
    await changeTicketsStatusRequest(data);
    setKeepOpen(false);
    setOpen(false);
    if (enableIcon) {
      enableIcon();
    }
    if (closeBar) {
      closeBar();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          justifyContent: "start",
          py: 0.5,
          px: 2,
        }}
        onClick={() => {
          setOpen(true);
          if (close) {
            close();
          }
        }}
      >
        <Typography sx={{ fontSize: 18 }}>Open</Typography>
      </Box>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          if (enableIcon) {
            enableIcon();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onMouseOver={() => setKeepOpen(true)}
      >
        <Box component="form" onSubmit={handleSubmit} sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ alignSelf: "center", fontSize: 28 }}
          >
            Open {selected.length > 1 ? "Tickets" : "Ticket"}
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            Are you sure you want to open the selected {selected.length > 1 ? "tickets" : "ticket"}?
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: 3 }}>
            <Button
              variant="contained"
              type="button"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                background: "#808080",
                "&:hover": { background: "#686a6b" },
              }}
              onClick={() => {
                setKeepOpen(false);
                setOpen(false);
                if (enableIcon) {
                  enableIcon();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                marginLeft: 2,
                background: "#267aad",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

SetOpen.propTypes = {
  selected: array,
  close: func,
  setKeepOpen: func,
  enableIcon: func,
  closeBar: func,
};

export { SetOpen };
