import { Autocomplete, Avatar, Chip, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { array, object } from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { changeTicketOperatorsRequest } from "src/api/changeTicketOperatorsRequest";
import { stringAvatar } from "src/utils/createAvatar";
import { returnGravatar } from "src/utils/getGravatar";

const TicketOperatorsChange = ({ assignees, ticket }) => {
  const { operators } = useSelector((state) => state.operators);
  const { user } = useSelector((state) => state.user);
  const [filter, setFilter] = useState("");

  const handleChange = async (e, values) => {
    let data;
    if (values.length > assignees.length) {
      data = {
        ticket_id: ticket.id,
        analyst_id: values[values.length - 1].id,
      };
    } else {
      const assignee = assignees.filter((x) => !values.includes(x))[0];
      data = {
        ticket_id: ticket.id,
        analyst_id: assignee.id,
      };
    }
    setFilter("");
    await changeTicketOperatorsRequest(data);
  };

  return (
    <Autocomplete
      options={operators || []}
      value={assignees || []}
      multiple
      disableClearable
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{ width: "100%", marginTop: 2, "& .MuiInputBase-root": { minHeight: "50px" } }}
      size="small"
      autoHighlight={true}
      onChange={handleChange}
      onInputChange={(e) => setFilter(e.target.value)}
      filterSelectedOptions
      filterOptions={(options) => {
        let newOptions = [];

        options.forEach((option) => {
          if (option.first_name === "Unassigned") {
            return;
          }
          if (
            !option.departments?.filter((dep) => dep.department_id === ticket.department_id)[0] &&
            ticket.department_id !== 1
          ) {
            return;
          }
          if (option.first_name === "My" && option.last_name === "Tickets") {
            newOptions.push({
              ...option,
              first_name: user.first_name,
              last_name: user.last_name,
            });
          } else {
            newOptions.push(option);
          }
        });
        if (!filter) {
          return newOptions;
        }
        newOptions = newOptions.filter((option) => {
          const name = `${option.first_name} ${option.last_name}`.toLowerCase();
          if (name.includes(filter.toLowerCase()) || option.email.includes(filter)) {
            return option;
          } else return null;
        });
        return newOptions;
      }}
      renderTags={(values, getTagProps) => {
        if (!values) return;

        return values?.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.id}
            label={`${option.first_name} ${option.last_name}`}
            avatar={
              <Avatar
                {...stringAvatar(
                  option.last_name ? `${option.first_name} ${option.last_name}` : option.email
                )}
                src={returnGravatar(option.email)}
              />
            }
            variant="outlined"
          />
        ));
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          {...props}
          key={option.id}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              {...stringAvatar(
                option.last_name ? `${option.first_name} ${option.last_name}` : option.email
              )}
              src={returnGravatar(option.email)}
            />
          </Box>
          <Typography sx={{ marginLeft: 2 }}>
            {option.first_name} {option.last_name}
          </Typography>
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Operators" />}
    />
  );
};

TicketOperatorsChange.propTypes = {
  assignees: array,
  ticket: object,
};

export { TicketOperatorsChange };
