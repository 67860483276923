import axiosInstance from "src/service/axios/axiosInstance";

export const logout = async () => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/auth/logout",
    });
  } catch (err) {
    return err.response;
  }
};
