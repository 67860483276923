import { Box, CircularProgress, Fab, Typography } from "@mui/material";
import React, { useContext, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { allCannedMessagesRequest } from "src/api/allCannedMessagesRequest";
import { filteredCannedMessagesRequest } from "src/api/filteredCannedMessagesRequest";
import { CannedMessagesForm } from "src/components/CannedMessagesForm";
import { SocketContext } from "src/context/socket";
import {
  addCannedMessage,
  deleteCannedMessages,
  setCannedMessages,
  updateCannedMessages,
} from "src/redux/actions/cannedMessagesActions";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useAtom } from "jotai";
import { cannedMessagesAtom, defaultCannedMessagesSearchValues } from "src/components/Searchbar";
import { ActiveCannedMessagesFilters } from "src/components/ActiveCannedMessagesFilters";
import { compareTwoObjects } from "src/utils/compareTwoObjects";

const CannedMessages = ({ sidebar }) => {
  const { cannedMessages } = useSelector((state) => state.cannedMessages);
  const [cannedMessageTemp, setCannedMessageTemp] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const bottom = useRef(false);
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const [values] = useAtom(cannedMessagesAtom);

  useEffect(() => {
    document.title = "Canned Messages";
  });

  useEffect(() => {
    if (!cannedMessages || cannedMessages.length === 0) {
      return;
    }
    socket.on("cannedMessageRemoved", (response) => dispatch(deleteCannedMessages(response)));
    socket.on("cannedMessageEdited", (response) => {
      const isExists = cannedMessages.filter((message) => message.id === response.id)[0];
      if (!isExists) {
        return;
      }
      setCannedMessageTemp(response);
    });
    socket.on("newCannedMessage", (response) => setNewMessage(response));

    return () => {
      socket.off("cannedMessageRemoved");
      socket.off("cannedMessageEdited");
      socket.off("newCannedMessage");
    };
    //eslint-disable-next-line
  }, [cannedMessages]);

  useEffect(() => {
    if (!newMessage) {
      return;
    }
    dispatch(addCannedMessage(newMessage));
  }, [newMessage, dispatch]);

  useEffect(() => {
    if (!cannedMessageTemp) {
      return;
    }

    dispatch(updateCannedMessages(cannedMessageTemp));
  }, [cannedMessageTemp, dispatch]);

  const initialGetCannedMessages = useCallback(async () => {
    let response;
    if (!compareTwoObjects(values, defaultCannedMessagesSearchValues)) {
      response = await filteredCannedMessagesRequest(new URLSearchParams({ ...values, offset: 0 }));
    } else {
      response = await allCannedMessagesRequest(0, 15);
    }
    if (response.status === 200) {
      dispatch(setCannedMessages(response.data.canned_messages));
      bottom.current = false;
    }
    setLoading(false);
  }, [dispatch, values]);

  const getCannedMessages = useCallback(async () => {
    let response;
    if (!compareTwoObjects(values, defaultCannedMessagesSearchValues)) {
      response = await filteredCannedMessagesRequest(
        new URLSearchParams({ ...values, offset: cannedMessages.length })
      );
    } else {
      response = await allCannedMessagesRequest(cannedMessages.length, 15);
    }
    if (response.status === 200) {
      dispatch(setCannedMessages([...cannedMessages, ...response.data.canned_messages]));
      bottom.current = false;
    }
    setLoading(false);
  }, [dispatch, cannedMessages, values]);

  const handleScroll = useCallback(
    (e) => {
      if (
        window.innerHeight + e.target.documentElement.scrollTop >=
        document.getElementById("messagesForm").scrollHeight
      ) {
        if (!bottom.current && cannedMessages.length >= 15) {
          bottom.current = true;
          getCannedMessages();
        }
      }
    },
    [cannedMessages, getCannedMessages]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.scrollTo(0, 0);
    initialGetCannedMessages();
  }, [location, initialGetCannedMessages]);

  return (
    <>
      <ActiveCannedMessagesFilters />
      <Box
        id="messagesForm"
        sx={{
          background: { md: "white" },
          marginRight: { xs: 2, md: 4 },
          marginLeft: { xs: 2, md: 4 },
          marginTop: 1,
          marginBottom: 5,
          borderRadius: 4,
          paddingY: { xs: 0.7, md: 3 },
          minHeight: "65vh",
          userSelect: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <CircularProgress color="primary" size={70} />
        ) : cannedMessages.length > 0 ? (
          <Box sx={{ width: "100%", alignSelf: "start" }}>
            <CannedMessagesForm cannedMessages={cannedMessages} sidebar={sidebar} />
          </Box>
        ) : (
          <Typography
            sx={{
              color: "#c4c4c4",
              fontFamily: ["Open Sans"].join(","),
              textAlign: "center",
              fontSize: { xs: 35, md: 47 },
            }}
          >
            No Canned Messages
          </Typography>
        )}
        <Fab
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: { xs: 10, md: 25 },
            right: { xs: 10, md: 34 },
            zIndex: 100,
            color: "white",
            background: "#267aad",
            "&:hover": {
              background: "#1976d2",
            },
          }}
          onClick={() => navigate("/addCannedMessage")}
        >
          <AddRoundedIcon />
        </Fab>
      </Box>
    </>
  );
};

CannedMessages.propTypes = {
  sidebar: PropTypes.bool,
};

export { CannedMessages };
