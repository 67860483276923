import React from "react";
import { Typography } from "@mui/material";
import { format } from "date-fns";

const Footer = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography sx={{ marginBottom: "1%" }}>© Copyright {format(new Date(), "yyyy")}</Typography>
    </div>
  );
};

export { Footer };
