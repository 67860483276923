import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { node } from "prop-types";
import { useSelector } from "react-redux";
import { defaultTicketsSearchValues, ticketsAtom } from "src/components/Searchbar";
import { useAtom } from "jotai";
import { compareTwoObjects } from "src/utils/compareTwoObjects";

const Notification = ({ children }) => {
  const { isLoading } = useSelector((state) => state.number);
  const [filterValues] = useAtom(ticketsAtom);

  const isFilter = useMemo(() => {
    return !compareTwoObjects(filterValues, defaultTicketsSearchValues, [
      "department",
      "isLoading",
    ]);
  }, [filterValues, defaultTicketsSearchValues]);

  return (
    <>
      {!isLoading && children && (
        <Box
          sx={{
            background: isFilter ? "#5c5c5c" : "#18d62e",
            minWidth: "25px",
            height: "25px",
            borderRadius: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: 3,
          }}
        >
          <Typography sx={{ fontWeight: "bold", color: "white" }}>{children}</Typography>
        </Box>
      )}
    </>
  );
};

Notification.propTypes = {
  children: node,
};

export { Notification };
