export const compareTwoObjects = (obj1, obj2, keysToExclude = []) => {
  let obj1Temp = { ...obj1 };
  let obj2Temp = { ...obj2 };

  keysToExclude.forEach((key) => {
    delete obj1Temp[key];
    delete obj2Temp[key];
  });

  const orderedObj1 = Object.keys(obj1Temp)
    .sort()
    .reduce((obj, key) => {
      obj[key] = obj1Temp[key];
      return obj;
    }, {});
  const orderedObj2 = Object.keys(obj2Temp)
    .sort()
    .reduce((obj, key) => {
      obj[key] = obj2Temp[key];
      return obj;
    }, {});
  return JSON.stringify(orderedObj1) === JSON.stringify(orderedObj2);
};
