import React from "react";
import { Box } from "@mui/material";
import AttachmentRoundedIcon from "@mui/icons-material/AttachmentRounded";
import { downloadFileRequest } from "src/api/downloadFileRequest";
import { object } from "prop-types";
import SnackbarUtils from "src/utils/SnackbarUtils";

const AttachmentCard = ({ file }) => {
  const handleDownload = async () => {
    await downloadFileRequest(file).then((response) => {
      if (response.status === 404) {
        SnackbarUtils.error("File not found!");
        return;
      }
      if (response.status >= 400) {
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.attached_file_name);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  return (
    <Box
      sx={{
        background: "#f7f7f7",
        width: { md: "fit-content" },
        maxWidth: { xs: "92%", md: "100%" },
        paddingLeft: 2,
        paddingRight: 1,
        py: 0.5,
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        marginLeft: { md: 2 },
        marginBottom: 1,
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={handleDownload}
    >
      <AttachmentRoundedIcon sx={{ color: "#525252", marginRight: 1, marginLeft: -1 }} />
      <Box
        sx={{
          textDecoration: "none",
          color: "#326ab3",
          marginTop: -0.5,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {file.attached_file_name}
      </Box>
    </Box>
  );
};

AttachmentCard.propTypes = {
  file: object,
};

export { AttachmentCard };
