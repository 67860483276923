import { ADD_TICKET, SET_TICKETS, UPDATE_TICKETS } from "src/redux/actionTypes/ticketsTypes";
/*eslint no-case-declarations: "error"*/
/*eslint-env es6*/

const initialState = {
  tickets: [],
  isLoading: true,
};

export default function ticketsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TICKETS:
      return {
        tickets: action.payload,
        isLoading: false,
      };
    case UPDATE_TICKETS:
      return {
        tickets: !window.location.hash.includes("filter")
          ? !window.location.hash.includes("All")
            ? [...state.tickets, ...action.payload.data]
                .map((ticket) => {
                  const Ticket = action.payload.data.filter(
                    (newTicket) => newTicket.id === ticket.id
                  )[0];

                  if (!Ticket) {
                    return ticket;
                  }
                  return Ticket;
                })
                .sort((a, b) => {
                  return a.id - b.id;
                })
                .filter(function (item, pos, ary) {
                  return !pos || item !== ary[pos - 1];
                })
                .filter(
                  (ticket) => ticket.department_id === action.payload.dep && ticket.status_id !== 3
                )
                .sort((a, b) => {
                  if (b.ts_updated_by_analyst > b.ts_updated_by_customer) {
                    if (a.ts_updated_by_analyst > a.ts_updated_by_customer) {
                      return b.ts_updated_by_analyst - a.ts_updated_by_analyst;
                    } else {
                      return b.ts_updated_by_analyst - a.ts_updated_by_customer;
                    }
                  } else {
                    if (a.ts_updated_by_analyst < a.ts_updated_by_customer) {
                      return b.ts_updated_by_customer - a.ts_updated_by_customer;
                    } else {
                      return b.ts_updated_by_customer - a.ts_updated_by_analyst;
                    }
                  }
                })
            : [...state.tickets, ...action.payload.data]
                .map((ticket) => {
                  const Ticket = action.payload.data.filter(
                    (newTicket) => newTicket.id === ticket.id
                  )[0];

                  if (!Ticket) {
                    return ticket;
                  }
                  return Ticket;
                })
                .sort((a, b) => {
                  return a.id - b.id;
                })
                .filter((item, pos, ary) => {
                  return !pos || item !== ary[pos - 1];
                })
                .filter((ticket) => ticket.status_id !== 3)
                .sort((a, b) => {
                  if (b.ts_updated_by_analyst > b.ts_updated_by_customer) {
                    if (a.ts_updated_by_analyst > a.ts_updated_by_customer) {
                      return b.ts_updated_by_analyst - a.ts_updated_by_analyst;
                    } else {
                      return b.ts_updated_by_analyst - a.ts_updated_by_customer;
                    }
                  } else {
                    if (a.ts_updated_by_analyst < a.ts_updated_by_customer) {
                      return b.ts_updated_by_customer - a.ts_updated_by_customer;
                    } else {
                      return b.ts_updated_by_customer - a.ts_updated_by_analyst;
                    }
                  }
                })
          : state.tickets,
        isLoading: false,
      };
    case ADD_TICKET:
      return {
        tickets: [action.payload.data, ...state.tickets],
        isLoading: false,
      };
    default:
      return state;
  }
}
