import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { style } from "src/styles/modalStyle";
import { addEmailRequest } from "src/api/addEmailRequest";
import { validateEmail } from "src/utils/validateEmail";
import { bool, func, number } from "prop-types";
import snackbarUtils from "../../utils/SnackbarUtils";

const AddEmail = ({
  defaultChecked,
  ticket_id,
  setKeepOpen = () => {},
  setKeepNoEmailsOpen = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [prevAdded, setPrevAdded] = useState([]);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      snackbarUtils.error("Email must be a valid email");
      return;
    }

    let exists = false;
    prevAdded.forEach((element) => {
      if (element.email === email) {
        exists = true;
      }
    });
    if (exists) {
      snackbarUtils.error("Duplicate Email");
      setEmail("");
      return;
    }
    const data = {
      email,
      analyst_email: checked,
      ticket_id,
    };
    const response = await addEmailRequest(data);
    if (response.status > 205) {
      return;
    }
    snackbarUtils.success("Email added successfully");
    setEmail("");
    setPrevAdded([...prevAdded, data]);
  };

  return (
    <>
      <Button
        sx={{
          textTransform: "none",
          width: "100%",
          justifyContent: "start",
        }}
        onClick={() => setOpen(true)}
      >
        Add Email Address
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setKeepOpen(false);
          setKeepNoEmailsOpen(false);
          setPrevAdded([]);
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{ ...style, background: "white" }}
          onMouseOver={() => {
            setKeepOpen(true);
            setKeepNoEmailsOpen(true);
          }}
          onTouchStart={() => {
            setKeepOpen(true);
            setKeepNoEmailsOpen(true);
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Add Email Address</Typography>
            <Tooltip title="Close">
              <IconButton
                sx={{ marginRight: -2, marginTop: -1 }}
                onClick={() => {
                  setKeepOpen(false);
                  setKeepNoEmailsOpen(false);
                  setPrevAdded([]);
                  setOpen(false);
                }}
              >
                <CloseRoundedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
          {prevAdded.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontWeight: "bold" }}>Email</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontWeight: "bold" }}>Bcc</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prevAdded.map((element) => (
                    <TableRow key={element.email}>
                      <TableCell>
                        <Typography>{element.email}</Typography>
                      </TableCell>
                      <TableCell>{element.analyst_email && <CheckRoundedIcon />}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              id="email"
              type="email"
              sx={{ width: "100%", marginTop: 2 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
            />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked((prev) => !prev)}
                    id="bcc"
                  />
                }
                sx={{ mt: 1 }}
                label="Bcc"
              />
              <Button
                type="submit"
                sx={{ fontWeight: "bold", textTransform: "none", fontSize: 17, mt: 1.5 }}
                disabled={!email}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

AddEmail.propTypes = {
  defaultChecked: bool,
  ticket_id: number,
  setKeepOpen: func,
  setKeepNoEmailsOpen: func,
};

export { AddEmail };
