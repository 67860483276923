import { SET_LOADING, SET_NUMBER } from "src/redux/actionTypes/numberTypes";

const initialState = {
  all: null,
  count: null,
  isLoading: true,
};

export default function numberReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NUMBER:
      return {
        all: action.payload.all,
        count: action.payload.ticketsNumber,
        isLoading: false,
      };
    case SET_LOADING:
      return {
        all: state.all,
        count: state.count,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}
