import { SET_USER, UPDATE_USER } from "src/redux/actionTypes/setUserType";

export const setUser = (user) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: user,
  });
};

export const updateUser = (allowedDepartments) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: allowedDepartments,
  });
};
