import bgtest from "src/images/aikidesk.svg";
import bgtestSmall from "src/images/aikidesk-small.svg";
import bgtest2 from "src/images/logo.png";
import pps from "src/images/pps.png";
import ppsSmall from "src/images/pps-small.png";
import ppsMid from "src/images/pps-mid.gif";
import lv from "src/images/lv.svg";
import lvSmall from "src/images/lv-small.svg";

const logos = {
  bgtest,
  bgtestSmall,
  bgtest2,
  pps,
  ppsSmall,
  ppsMid,
  lv,
  lvSmall,
};

export default logos;
