import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "src/api/getUser";
import { Auth } from "src/Auth";
import { Footer } from "src/components/Footer";
import { setDepartments } from "src/redux/actions/setDepartments";
import { setUser } from "./redux/actions/seUser";
import { UnAuth } from "src/UnAuth";
import { SocketContext, socket } from "src/context/socket";
import { setPreferences } from "src/redux/actions/preferencesActions";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { getCookie } from "src/utils/getCookie";
import { instances } from "src/helpers/instances";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const oneTimeFilter = useRef(true);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const temp = window.location.hash.split("#")[1];
    if (temp === "/" || !temp) {
      return;
    }
    if (temp.includes("redirect")) {
      setRedirect(temp);
      return;
    }
    setRedirect(`/?redirect=${encodeURIComponent(temp)}`);
  }, []);

  useEffect(() => {
    if (!oneTimeFilter.current) {
      return;
    }
    if (loading) {
      return;
    }
    if (user) {
      return;
    }
    oneTimeFilter.current = false;
    if (window.location.hash.includes("forgotPassword")) {
      return;
    }
    navigate(redirect);
  }, [user, loading, redirect, navigate]);

  const getUserFn = useCallback(async () => {
    const response = await getUser();
    if (response.status === 200) {
      await dispatch(setDepartments(response.data.departments));
      await dispatch(setPreferences(response.data.preferences));
      await dispatch(setUser(response.data.user));
      const hash = window.location.hash;
      if (!hash || hash.length < 5) {
        if (response.data.user.departments.length === 1) {
          const dep = [...response.data.departments].filter(
            (dep) => dep.id === response.data.user.departments[0].department_id
          )[0];
          navigate(`/${dep.title.replaceAll(" ", "")}`);
        } else if (response.data.user.default_dep_id !== 0) {
          const defaultDep = [...response.data.departments].filter(
            (dep) => dep.id === response.data.user.default_dep_id
          )[0];
          navigate(`/${defaultDep.title.replaceAll(" ", "")}`);
        }
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [dispatch]);

  useEffect(() => {
    const instance = instances[window.location.origin];
    if (!getCookie(`${instance}_access_key`)) {
      setLoading(false);
      return;
    }
    getUserFn();
  }, [getUserFn]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <SnackbarProvider maxSnack={3}>
        <SocketContext.Provider value={socket}>
          <div style={{ minHeight: "94vh" }}>
            {loading ? (
              <Box
                sx={{
                  position: "absolute",
                  width: "100vw",
                  height: "100vh",
                  top: 0,
                  left: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={70} />
              </Box>
            ) : user ? (
              <Auth />
            ) : (
              <UnAuth />
            )}
          </div>
        </SocketContext.Provider>
        <SnackbarUtilsConfigurator />
        <Footer />
      </SnackbarProvider>
    </div>
  );
}

export default App;
