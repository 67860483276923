import axiosInstance from "src/service/axios/axiosInstance";

export const editProfileRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/user/editProfile",
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        notifications: data.notifications,
        email: data.email,
        defaultDepartment: data.defaultDepartment,
      },
    });
  } catch (err) {
    return err.response;
  }
};
