import axiosInstance from "src/service/axios/axiosInstance";

export const changeTicketOperatorsRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/tickets/changeTicketOperators",
      data: {
        ticket_id: data.ticket_id,
        analyst_id: data.analyst_id,
      },
    });
  } catch (err) {
    return err.response;
  }
};
