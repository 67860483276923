import axiosInstance from "src/service/axios/axiosInstance";

export const addReminderRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/tickets/addReminder",
      data: {
        ticket_id: data.ticket_id,
        date: data.date,
        analyst_only: data.analyst_only,
        message: data.message,
      },
    });
  } catch (err) {
    return err.response;
  }
};
