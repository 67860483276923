import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { downloadFileRequest } from "src/api/downloadFileRequest";

const Download = () => {
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.user);

  const handleDownload = useCallback(async () => {
    const file = {
      id: null,
      attached_file_name: null,
      key: encodeURIComponent(encodeURIComponent(searchParams.get("tkey"))),
    };
    await downloadFileRequest(file).then((response) => {
      if (response.status >= 400) {
        if (response.status === 401) {
          return (window.location.href = "/");
        }
        return;
      }
      const header = response.headers.get("Content-Disposition");
      const parts = header.split(";");
      const filename = parts[1].split("=")[1].replaceAll('"', "");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      window.close();
    });
  }, [searchParams]);

  useEffect(() => {
    if (!user) {
      return;
    }
    handleDownload();
  }, [user, handleDownload]);

  return <div></div>;
};

export { Download };
