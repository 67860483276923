import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CannedMessagesSearch } from "src/components/CannedMessagesSearch";
import { TicketsSearch } from "src/components/TicketsSearch";
import { array, bool, func, string } from "prop-types";

const SearchOptions = (props) => {
  const [keepOpen, setKeepOpen] = useState(false);
  const {
    route,
    setOpen,
    submit,
    sidebar,
    from,
    setFrom,
    department,
    setDepartment,
    assignee,
    setAssignee,
    before,
    setBefore,
    after,
    setAfter,
    status,
    setStatus,
    version,
    setVersion,
    createdBy,
    setCreatedBy,
    clearSearch,
  } = props;
  const wrapperRef = useRef(null);

  const changeKeepOpen = (newState) => {
    setKeepOpen(newState);
  };

  const clearForm = () => {
    clearSearch();
    setOpen();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !keepOpen) {
        setOpen();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, keepOpen, setOpen]);

  return (
    <Box
      ref={wrapperRef}
      sx={{
        position: "absolute",
        top: "80px",
        left: sidebar ? "350px" : "150px",
        transition: "all, 0.15s",
        width: "700px",
        background: "#fcfcfc",
        borderRadius: 5,
        boxShadow: 4,
        overflow: "auto",
        zIndex: 99,
      }}
    >
      <Box sx={{ margin: "15px" }}>
        {route.includes("/cannedMessage") ? (
          <CannedMessagesSearch
            clearForm={clearForm}
            setKeepOpen={changeKeepOpen}
            submit={submit}
            after={after}
            before={before}
            setAfter={setAfter}
            setBefore={setBefore}
            createdBy={createdBy}
            setCreatedBy={setCreatedBy}
          />
        ) : (
          <TicketsSearch
            setKeepOpen={changeKeepOpen}
            clearForm={clearForm}
            submit={submit}
            from={from}
            setFrom={setFrom}
            department={department}
            setDepartment={setDepartment}
            assignee={assignee}
            setAssignee={setAssignee}
            after={after}
            setAfter={setAfter}
            before={before}
            setBefore={setBefore}
            status={status}
            setStatus={setStatus}
            version={version}
            setVersion={setVersion}
          />
        )}
      </Box>
    </Box>
  );
};

SearchOptions.propTypes = {
  route: string,
  setOpen: func,
  submit: func,
  sidebar: bool,
  from: array,
  setFrom: func,
  department: string,
  setDepartment: func,
  assignee: array,
  setAssignee: func,
  before: string,
  setBefore: func,
  after: string,
  setAfter: func,
  status: array,
  setStatus: func,
  version: string,
  setVersion: func,
  createdBy: string,
  setCreatedBy: func,
  clearSearch: func,
};

export { SearchOptions };
