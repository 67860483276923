import { Box, Button, IconButton, MenuItem, Modal, TextField, Typography } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState } from "react";
import { style } from "src/styles/modalStyle";
import { changeTicketsPriorityRequest } from "src/api/changeTicketsPriorityRequest";
import { array, bool, func } from "prop-types";

const ChangePriority = ({
  selected,
  close,
  small,
  setKeepOpen = () => {},
  enableIcon,
  closeBar,
}) => {
  const [open, setOpen] = useState(false);
  const [priority, setPriority] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setKeepOpen(false);
    const data = {
      ids: selected,
      priority,
    };
    if (priority !== "") {
      await changeTicketsPriorityRequest(data);
    }
    setOpen(false);
    if (enableIcon) {
      enableIcon();
    }
    if (closeBar) {
      closeBar();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginLeft: { md: small ? 0 : 2 },
          width: { xs: "100%", md: small ? "100%" : "fit-content" },
          justifyContent: small ? "start" : "center",
          py: small ? 0.5 : 1,
          px: small ? 2 : 1,
          borderRadius: !small && 3,
          transition: "background, 0.2s",
          "&:hover": { background: !small && "#e6e6e6" },
        }}
        onClick={() => {
          setOpen(true);
          if (close) {
            close();
          }
        }}
      >
        {!small && <WarningRoundedIcon />}
        <Typography sx={{ fontSize: 18 }}>{small ? "Priority" : "PRIORITY"}</Typography>
      </Box>
      <Modal
        open={open}
        onClose={() => {
          small && close();
          setKeepOpen(false);
          setOpen(false);
          if (enableIcon) {
            enableIcon();
          }
        }}
        onMouseOver={() => setKeepOpen(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" onSubmit={handleSubmit} sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ alignSelf: "center", fontSize: 28 }}
          >
            Change Priority
          </Typography>
          <Typography variant="p">Select priority</Typography>
          <TextField
            select
            label="Priority"
            sx={{ marginTop: 2 }}
            value={priority}
            onFocus={() => setKeepOpen(true)}
            onBlur={() => setKeepOpen(false)}
            onChange={(e) => setPriority(e.target.value)}
            SelectProps={{ IconComponent: () => null }}
            InputProps={{
              endAdornment: priority && (
                <IconButton sx={{ margin: 0, padding: 0 }} onClick={() => setPriority("")}>
                  <CloseRoundedIcon />
                </IconButton>
              ),
            }}
          >
            <MenuItem value="1">Low</MenuItem>
            <MenuItem value="2">Medium</MenuItem>
            <MenuItem value="3">High</MenuItem>
            <MenuItem value="4">Urgent</MenuItem>
          </TextField>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: 3 }}>
            <Button
              variant="contained"
              type="button"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                background: "#808080",
                "&:hover": { background: "#686a6b" },
              }}
              onClick={() => {
                small && close();
                setKeepOpen(false);
                setOpen(false);
                if (enableIcon) {
                  enableIcon();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={Boolean(!priority)}
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                marginLeft: 2,
                background: "#267aad",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ChangePriority.propTypes = {
  selected: array,
  close: func,
  small: bool,
  setKeepOpen: func,
  enableIcon: func,
  closeBar: func,
};

export { ChangePriority };
