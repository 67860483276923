import React from "react";
import { Box } from "@mui/material";
import { node } from "prop-types";

const InputBase = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "4%",
      }}
    >
      <Box
        sx={{
          background: "#fcfcfc",
          width: { xs: "90%", md: "450px" },
          borderRadius: 4,
          boxShadow: `0 3px 10px #b0afae`,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

InputBase.propTypes = {
  children: node,
};

export { InputBase };
