import { SET_OPERATORS } from "src/redux/actionTypes/operatorsTypes";

const initialState = {
  operators: null,
  isLoading: true,
};

export default function operatorsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPERATORS:
      return {
        operators: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
