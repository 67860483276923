import { Autocomplete, Box, Button, MenuItem, Modal, TextField, Typography } from "@mui/material";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import React, { useState } from "react";
import { style } from "src/styles/modalStyle";
import { useSelector } from "react-redux";
import { changeTicketsOperatorsRequest } from "src/api/changeTicketsOperatorsRequest";
import { array, bool, func } from "prop-types";

const ChangeOperators = ({
  selected,
  close,
  small,
  setKeepOpen = () => {},
  enableIcon,
  closeBar,
}) => {
  const [open, setOpen] = useState(false);
  const [operatorsTemp, setOperators] = useState([]);
  const [action, setAction] = useState("1");
  const { operators } = useSelector((state) => state.operators);
  const { user } = useSelector((state) => state.user);
  const [filter, setFilter] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setKeepOpen(false);
    const data = {
      ids: selected,
      action: parseInt(action),
      operators: operatorsTemp.map((op) => {
        return op.id;
      }),
    };
    if (operators.length > 0) {
      await changeTicketsOperatorsRequest(data);
    }
    setOpen(false);
    if (enableIcon) {
      enableIcon();
    }
    if (closeBar) {
      closeBar();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginLeft: { md: small ? 0 : 2 },
          width: { xs: "100%", md: small ? "100%" : "fit-content" },
          justifyContent: small ? "start" : "center",
          py: small ? 0.5 : 1,
          px: small ? 2 : 1,
          borderRadius: !small && 3,
          transition: "background, 0.2s",
          "&:hover": { background: !small && "#e6e6e6" },
        }}
        onClick={() => {
          setOpen(true);
          if (close) {
            close();
          }
        }}
      >
        {!small && <BadgeRoundedIcon />}
        <Typography sx={{ marginLeft: !small && 1, fontSize: 18 }}>
          {small ? "Operators" : "OPERATORS"}
        </Typography>
      </Box>
      <Modal
        open={open}
        onClose={() => {
          small && close();
          setKeepOpen(false);
          setOpen(false);
          if (enableIcon) {
            enableIcon();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onMouseOver={() => setKeepOpen(true)}
      >
        <Box component="form" onSubmit={handleSubmit} sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ alignSelf: "center", fontSize: 28 }}
          >
            Change Operators
          </Typography>
          <Typography variant="p">Select action</Typography>
          <TextField
            select
            label="Action"
            sx={{ marginTop: 2 }}
            value={action}
            onFocus={() => setKeepOpen(true)}
            onBlur={() => setKeepOpen(false)}
            onChange={(e) => setAction(e.target.value)}
            SelectProps={{ IconComponent: () => null }}
          >
            <MenuItem value="1">Assign Operators</MenuItem>
            <MenuItem value="0">Remove Operators</MenuItem>
          </TextField>
          <Typography variant="p" sx={{ marginTop: 2 }}>
            Select operators
          </Typography>
          <Autocomplete
            sx={{ marginTop: 2 }}
            options={operators}
            multiple
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={operatorsTemp}
            onChange={(e, values) => {
              setOperators(values);
              setFilter("");
            }}
            onInputChange={(e) => setFilter(e.target.value)}
            autoHighlight={true}
            filterSelectedOptions
            filterOptions={(options) => {
              let newOptions = [];
              options.forEach((option) => {
                if (option.first_name === "Unassigned") {
                  return;
                }
                if (option.first_name === "My" && option.last_name === "Tickets") {
                  newOptions.push({
                    ...option,
                    first_name: user.first_name,
                    last_name: user.last_name,
                  });
                } else {
                  newOptions.push(option);
                }
              });
              if (!filter) {
                return newOptions;
              }
              newOptions = newOptions.filter((option) => {
                const name = `${option.first_name} ${option.last_name}`.toLowerCase();
                if (name.includes(filter.toLowerCase()) || option.email.includes(filter)) {
                  return option;
                } else return null;
              });
              return newOptions;
            }}
            onFocus={() => setKeepOpen(true)}
            onBlur={() => setKeepOpen(false)}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.first_name} {option.last_name}
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Operators" />}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: 3,
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              type="button"
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                background: "#808080",
                "&:hover": { background: "#686a6b" },
              }}
              onClick={() => {
                small && close();
                setKeepOpen(false);
                setOpen(false);
                if (enableIcon) {
                  enableIcon();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={Boolean(operatorsTemp.length === 0)}
              sx={{
                fontSize: 17,
                borderRadius: 5,
                width: "49%",
                background: "#267aad",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ChangeOperators.propTypes = {
  selected: array,
  close: func,
  small: bool,
  setKeepOpen: func,
  enableIcon: func,
  closeBar: func,
};

export { ChangeOperators };
