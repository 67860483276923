import axiosInstance from "src/service/axios/axiosInstance";

export const addEmailRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/emails/add",
      data: {
        email: data.email,
        ticket_id: data.ticket_id,
        analyst_email: data.analyst_email,
      },
    });
  } catch (err) {
    return err.response;
  }
};
