import {
  alpha,
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { returnGravatar } from "src/utils/getGravatar";
import { stringSmallAvatar } from "src/utils/ticketAvatar";
import { colorCode } from "src/styles/priorityColorCode";
import { CloseTickets } from "src/components/CloseTickets";
import { SetOpen } from "src/components/SetOpen";
import { SetOnHold } from "src/components/SetOnHold";
import { ChangePriority } from "src/components/ChangePriority";
import { ChangeDepartment } from "src/components/ChangeDepartment";
import { ChangeOperators } from "src/components/ChangeOperators";
import { useNavigate } from "react-router-dom";
import { bool, func, object } from "prop-types";
import { NameField } from "src/components/TicketsForm/NameField";
import { SubjectField } from "src/components/TicketsForm/SubjectField";

const WebCard = ({ ticket, handleSelectChange, checked }) => {
  const [showIcon, setShowIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [keepHidden, setKeepHidden] = useState(false);
  const navigate = useNavigate();
  const openMore = Boolean(anchorEl);
  const leaveTimer = useRef();
  const leaveDelay = 100;
  const wrapperRef = useRef(null);
  const [preventAction, setPreventAction] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTimeout(() => {
          if (open) {
            setOpen(false);
          }
        }, 250);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, open]);

  const handleLeave = () => {
    clearTimeout(leaveTimer.current);
    leaveTimer.current = setTimeout(() => {
      setAnchorEl(null);
    }, leaveDelay);
  };

  const handleClick = async (e) => {
    e.stopPropagation();
    if (preventAction) {
      return;
    }

    if (e.ctrlKey) {
      window.open(
        window.location.origin + `/#/ticket?id=${ticket.id}`,
        "_blank",
        "noopener,noreferrer"
      );
      return;
    }

    navigate(`/ticket?id=${ticket.id}`);
  };

  const handleClose = () => {
    setKeepHidden(true);
    setOpen(false);
    setShowIcon(false);
  };

  return (
    <ListItem
      divider
      sx={{
        display: { xs: "none", md: "flex" },
        width: "100%",
        cursor: "pointer",
        alignItems: "center",
        background: showIcon
          ? ticket?.status_id === 2
            ? "#c4c4c4"
            : "#f7f7f7"
          : ticket?.status_id === 2
          ? "#e0e0e0"
          : "",
        py: 0,
      }}
      onMouseOver={() => {
        if (keepHidden) {
          return;
        }
        setShowIcon(true);
      }}
      onMouseLeave={() => setShowIcon(false)}
    >
      <Box sx={{ width: "40px", alignSelf: "start", mt: "8px" }}>
        <Checkbox checked={checked} onChange={() => handleSelectChange(ticket?.id)} />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          alingItems: "center",
          flexDirection: "row",
          marginLeft: 2,
          py: 1.7,
        }}
        onClick={handleClick}
      >
        <NameField
          ticket={ticket}
          clearHover={() => setShowIcon(false)}
          setPreventAction={setPreventAction}
        />
        <SubjectField
          ticket={ticket}
          clearHover={() => setShowIcon(false)}
          setPreventAction={setPreventAction}
        />
        <Box sx={{ width: "16%", display: "flex", alingItems: "center" }}>
          {ticket?.status_id !== 0 && ticket?.assignees.length !== 0 && (
            <>
              <Popper
                open={openMore}
                anchorEl={anchorEl}
                onMouseEnter={() => {
                  clearTimeout(leaveTimer.current);
                }}
                onMouseLeave={handleLeave}
              >
                <Paper
                  sx={{
                    marginTop: 1.8,
                    display: "flex",
                    flexDirection: "column",
                    background: alpha("#000000", 0.6),
                    px: 1,
                    py: 0.2,
                  }}
                >
                  {ticket.assignees.slice(3).map((assignee) => (
                    <Typography
                      variant="p"
                      sx={{ fontSize: 11.6, color: "white" }}
                      key={assignee.id}
                    >
                      {assignee.analyst.first_name} {assignee.analyst.last_name}
                    </Typography>
                  ))}
                </Paper>
              </Popper>
              <AvatarGroup
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  "& .MuiAvatar-root": {
                    width: 25,
                    height: 25,
                    fontSize: 13,
                  },
                }}
                componentsProps={{
                  additionalAvatar: {
                    onMouseEnter: (event) => {
                      clearTimeout(leaveTimer.current);
                      setAnchorEl(event.currentTarget);
                    },
                    onMouseLeave: handleLeave,
                  },
                }}
                max={4}
              >
                {ticket.assignees.map((assignee) => (
                  <Tooltip
                    key={assignee.id}
                    title={`${assignee.analyst.first_name} ${assignee.analyst.last_name}`}
                  >
                    <Avatar
                      {...stringSmallAvatar(
                        `${assignee.analyst.first_name} ${assignee.analyst.last_name}`
                      )}
                      alt={`${assignee.analyst.first_name} ${assignee.analyst.last_name}`}
                      src={returnGravatar(assignee.analyst.email)}
                    />
                  </Tooltip>
                ))}
              </AvatarGroup>
            </>
          )}
        </Box>
        <Box
          sx={{
            width: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "max-content",
          }}
        >
          {ticket?.ts_updated_by_analyst > ticket?.ts_updated_by_customer ? (
            <Typography
              variant="p"
              sx={{
                marginLeft: 2,
                color: ticket?.status_id === 3 ? "#8a8a8a" : "black",
                fontWeight:
                  ticket.status_id !== 3 &&
                  ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                  "bold",
              }}
            >
              {format(new Date(ticket?.ts_updated_by_analyst * 1000), "dd MMM yyyy HH:mm")}
            </Typography>
          ) : (
            <Typography
              variant="p"
              sx={{
                marginLeft: 2,
                color: ticket?.status_id === 3 ? "#8a8a8a" : "black",
                fontWeight:
                  ticket.status_id !== 3 &&
                  ticket.ts_viewed_by_analyst < ticket.ts_updated_by_customer &&
                  "bold",
              }}
            >
              {format(new Date(ticket?.ts_updated_by_customer * 1000), "dd MMM yyyy HH:mm")}
            </Typography>
          )}
          {ticket.messages.length > 0 &&
            ticket.messages[0].admin_id === 0 &&
            ticket.messages[0].analyst_id === 0 && (
              <Tooltip title={`${ticket.user.first_name} ${ticket.user.last_name}`}>
                <Avatar
                  {...stringSmallAvatar(`${ticket.user.first_name} ${ticket.user.last_name}`)}
                  alt={`${ticket.user.first_name} ${ticket.user.last_name}`}
                  src={returnGravatar(ticket.user.email)}
                />
              </Tooltip>
            )}
          {ticket.messages.length > 0 &&
            ticket.messages[0].admin_id === 0 &&
            ticket.messages[0].analyst_id > 0 && (
              <Tooltip
                title={`${ticket.messages[0].analyst.first_name} ${ticket.messages[0].analyst.last_name}`}
              >
                <Avatar
                  {...stringSmallAvatar(
                    `${ticket.messages[0].analyst.first_name} ${ticket.messages[0].analyst.last_name}`
                  )}
                  alt={`${ticket.messages[0].analyst.first_name} ${ticket.messages[0].analyst.last_name}`}
                  src={returnGravatar(ticket.messages[0].analyst.email)}
                />
              </Tooltip>
            )}
          {ticket.messages.length > 0 && ticket.messages[0].admin_id > 0 && (
            <Tooltip title="Administrator">
              <Avatar {...stringSmallAvatar(`Administrator`)} />
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            width: "6%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            height: "max-content",
            mt: "4px",
          }}
        >
          <Tooltip title={ticket?.priority?.title}>
            <Box
              sx={{
                width: 20,
                height: 20,
                background: alpha(
                  colorCode[ticket?.priority?.id],
                  ticket.status_id === 3 ? 0.4 : 1
                ),
                borderRadius: 50,
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      {showIcon && (
        <Tooltip title="Show options">
          <IconButton
            sx={{ position: "absolute", right: 5 }}
            onClick={() => {
              if (!open) {
                setOpen((prev) => !prev);
              }
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      )}

      <Box
        ref={wrapperRef}
        sx={{
          background: "#fcfcfc",
          position: "absolute",
          display: open ? "block" : "none",
          right: "10px",
          marginTop: "300px",
          boxShadow: 3,
          borderRadius: 4,
          zIndex: 2,
        }}
        onMouseOver={() => setShowIcon(false)}
      >
        <Box
          sx={{
            mt: 1.3,
            transition: "background, 0.2s",
            "&:hover": { background: "#e6e6e6" },
          }}
        >
          <CloseTickets
            selected={[ticket.id]}
            close={handleClose}
            enableIcon={() => setKeepHidden(false)}
            small={true}
          />
        </Box>

        <Box
          sx={{
            transition: "background, 0.2s",
            "&:hover": { background: "#e6e6e6" },
          }}
        >
          <SetOpen
            selected={[ticket.id]}
            close={handleClose}
            enableIcon={() => setKeepHidden(false)}
          />
        </Box>
        <Box
          sx={{
            transition: "background, 0.2s",
            "&:hover": { background: "#e6e6e6" },
          }}
        >
          <SetOnHold
            selected={[ticket.id]}
            close={handleClose}
            enableIcon={() => setKeepHidden(false)}
            small={true}
          />
        </Box>
        <Box
          sx={{
            transition: "background, 0.2s",
            "&:hover": { background: "#e6e6e6" },
          }}
        >
          <ChangePriority
            selected={[ticket.id]}
            close={handleClose}
            enableIcon={() => setKeepHidden(false)}
            small={true}
          />
        </Box>
        <Box
          sx={{
            transition: "background, 0.2s",
            "&:hover": { background: "#e6e6e6" },
          }}
        >
          <ChangeDepartment
            selected={[ticket.id]}
            close={handleClose}
            enableIcon={() => setKeepHidden(false)}
            small={true}
          />
        </Box>

        <Box
          sx={{
            mb: 1.3,
            transition: "background, 0.2s",
            "&:hover": { background: "#e6e6e6" },
          }}
        >
          <ChangeOperators
            selected={[ticket.id]}
            close={handleClose}
            enableIcon={() => setKeepHidden(false)}
            small={true}
          />
        </Box>
      </Box>
    </ListItem>
  );
};

WebCard.propTypes = {
  ticket: object,
  handleSelectChange: func,
  checked: bool,
};

export { WebCard };
