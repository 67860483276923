import React from "react";
import logos from "src/images";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/Routes";
import { useState } from "react";
import { useEffect } from "react";
import { instances } from "src/helpers/instances";

const UnAuth = () => {
  const navigate = useNavigate();
  const [instance, setInstance] = useState("");

  useEffect(() => {
    setInstance(instances[window.location.origin]);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 40 }}>
        <img
          src={logos[instance]}
          style={{ cursor: "pointer", maxHeight: "50px" }}
          alt="Logo"
          onClick={() => navigate("/")}
        />
      </div>
      <AppRoutes />
    </div>
  );
};

export { UnAuth };
