import { SET_USER, UPDATE_USER } from "src/redux/actionTypes/setUserType";

const initialState = {
  user: null,
  isLoading: true,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        user: action.payload,
        isLoading: false,
      };
    case UPDATE_USER: {
      return {
        user: {
          ...state.user,
          departments: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
