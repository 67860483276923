import Cookies from "js-cookie";
import { instances } from "src/helpers/instances";

export const setTokens = (headers) => {
  const instance = instances[window.location.origin];
  const authHeader = headers["authorization"];
  const refreshHeader = headers["authorizationrefresh"];
  if (!authHeader || !refreshHeader) return;
  const authToken = authHeader.split(" ")[1];
  const refreshToken = refreshHeader.split(" ")[1];
  if (refreshToken) {
    localStorage.setItem(`${instance}_refresh_key`, refreshToken);
  }
  if (authToken) {
    let expires = new Date();
    expires.setDate(expires.getDate() + 10);
    Cookies.set(`${instance}_access_key`, authToken, { expires: expires });
  }
};
