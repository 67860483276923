import { Autocomplete, Box, TextField } from "@mui/material";
import { format } from "date-fns";
import { func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const MobileCannedMessagesSearch = ({
  after,
  before,
  createdBy,
  setAfter,
  setBefore,
  setCreatedBy,
}) => {
  const { operators } = useSelector((state) => state.operators);
  const [options, setOptions] = useState(
    operators && [
      {
        email: "administrator",
        id: 0,
        first_name: "Administrator",
        last_name: "",
      },
      ...operators,
    ]
  );
  const { user } = useSelector((state) => state.user);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (!operators) {
      return;
    }
    const newOptions = [
      {
        email: "administrator",
        id: 0,
        first_name: "Administrator",
        last_name: "",
      },
      ...operators,
    ];
    setOptions(newOptions);
  }, [operators]);

  const changeCreatedBy = (_, values) => {
    setCreatedBy(values);
  };

  const changeFromDate = (e) => {
    setAfter(e.target.value);
  };

  const changeToDate = (e) => {
    setBefore(e.target.value);
  };

  return (
    <Box>
      <Autocomplete
        sx={{
          display: "inline-flex",
          minWidth: "250px",
          marginLeft: 2,
          ".MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap !important",
          },
        }}
        options={options || []}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={createdBy || null}
        onChange={changeCreatedBy}
        onFocus={() => {
          setFilter("");
        }}
        onInputChange={(e) => setFilter(e?.target?.value)}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        autoHighlight={true}
        filterOptions={(options) => {
          let newOptions = [];
          options.forEach((option) => {
            if (option.first_name === "Unassigned") {
              return;
            }
            if (option.first_name === "My" && option.last_name === "Tickets") {
              newOptions.push({
                ...option,
                first_name: user.first_name,
                last_name: user.last_name,
              });
            } else {
              newOptions.push(option);
            }
          });
          if (!filter) {
            return newOptions;
          }
          newOptions = newOptions.filter((option) => {
            const name = `${option.first_name} ${option.last_name}`.toLowerCase();
            if (name.includes(filter.toLowerCase()) || option.email.includes(filter)) {
              return option;
            } else return null;
          });
          return newOptions;
        }}
        renderInput={(params) => <TextField {...params} label="Created By" />}
      />
      <TextField
        type="date"
        id="fromDate"
        value={after}
        onChange={changeFromDate}
        InputProps={{
          inputProps: {
            max: before ? before : format(new Date(), "yyyy-MM-dd"),
          },
          style: { paddingRight: 10 },
        }}
        InputLabelProps={{ shrink: true }}
        sx={{ width: "150px", marginLeft: 2 }}
        label="Updated From"
      />
      <TextField
        type="date"
        id="fromDate"
        value={before}
        onChange={changeToDate}
        InputProps={{
          inputProps: {
            min: after && after,
            max: format(new Date(), "yyyy-MM-dd"),
          },
          style: { paddingRight: 10 },
        }}
        InputLabelProps={{ shrink: true }}
        sx={{ width: "150px", marginLeft: 2, marginRight: 2 }}
        label="Updated To"
      />
    </Box>
  );
};

MobileCannedMessagesSearch.propTypes = {
  after: string,
  before: string,
  createdBy: string,
  setAfter: func,
  setBefore: func,
  setCreatedBy: func,
};

export { MobileCannedMessagesSearch };
