import {
  ADD_CANNED_MESSAGES,
  DELETE_CANNED_MESSAGES,
  SET_CANNED_MESSAGES,
  UPDATE_CANNED_MESSAGES,
} from "src/redux/actionTypes/cannedMessagesTypes";

const initialState = {
  cannedMessages: [],
  isLoading: true,
};

export default function cannedMessagesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CANNED_MESSAGES:
      return {
        cannedMessages: action.payload.sort((a, b) => {
          return b.ts_updated - a.ts_updated;
        }),
        isLoading: false,
      };
    case ADD_CANNED_MESSAGES:
      return {
        cannedMessages: !window.location.hash.includes("filter")
          ? [...state.cannedMessages, action.payload].sort((a, b) => {
              return b.ts_updated - a.ts_updated;
            })
          : state.cannedMessages,
        isLoading: false,
      };
    case UPDATE_CANNED_MESSAGES:
      return {
        cannedMessages: state.cannedMessages
          .map((message) => {
            if (message.id === action.payload.id) {
              return action.payload;
            } else {
              return message;
            }
          })
          .sort((a, b) => {
            return b.ts_updated - a.ts_updated;
          }),
        isLoading: false,
      };
    case DELETE_CANNED_MESSAGES:
      return {
        cannedMessages: state.cannedMessages.filter(
          (message) => !action.payload.includes(message.id)
        ),
        isLoading: false,
      };
    default:
      return state;
  }
}
