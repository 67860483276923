import React from "react";
import { Box } from "@mui/material";
import { node } from "prop-types";

const EmailPopup = ({ children }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        background: "#fcfcfc",
        width: "max-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        ml: 1,
        mt: 0.5,
        borderRadius: 2,
        boxShadow: 1,
        px: 1,
        py: 0.5,
        zIndex: 99,
      }}
    >
      {children}
    </Box>
  );
};

EmailPopup.propTypes = {
  children: node,
};

export { EmailPopup };
