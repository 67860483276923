import { Box, Typography } from "@mui/material";
import { array, object } from "prop-types";
import React, { useState } from "react";
import { Message } from "src/components/TicketMessages/Message";

const TicketMessages = ({ messages, customer, lastMessages, firstMessage }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ mx: { md: 1 } }}>
      {firstMessage && <Message message={firstMessage} customer={customer} />}
      {messages.length > 0 && (
        <>
          <Box sx={{ display: open ? "block" : "none", marginTop: 1 }}>
            {messages.map((message) => {
              return (
                <Box key={message.id}>
                  <Message message={message} customer={customer} />
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              cursor: "pointer",
              marginTop: 1,
              width: "100%",
              display: open ? "none" : "flex",
              justifyContent: "center",
              color: "#5c5c5c",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Typography>
              Show {messages.length} more {messages.length > 1 ? "messages" : "message"}
            </Typography>
          </Box>
        </>
      )}
      {lastMessages.map((message) => (
        <Message key={message.id} message={message} customer={customer} />
      ))}
    </Box>
  );
};

TicketMessages.propTypes = {
  messages: array,
  customer: object,
  lastMessages: array,
  firstMessage: object,
};

export { TicketMessages };
