import React from "react";
import { io } from "socket.io-client";

export const socket =
  process.env.NODE_ENV === "development" || !process.env.NODE_ENV
    ? io(process.env.REACT_APP_BACKEND_URL, {
        autoConnect: false,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        transports: ["websocket"],
        auth: {
          token: "",
        },
      })
    : io(
        ("/",
        {
          path: "/api/socket.io",
          autoConnect: false,
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          reconnectionAttempts: Infinity,
          transports: ["websocket"],
          auth: {
            token: "",
          },
        })
      );
export const SocketContext = React.createContext();
