import axiosInstance from "src/service/axios/axiosInstance";

export const addCannedMessageRequest = async (data) => {
  try {
    return await axiosInstance({
      method: "post",
      url: "/cannedMessages/addCannedMessage",
      data,
    });
  } catch (err) {
    return err.response;
  }
};
