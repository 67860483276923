export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: "90vw", md: 400 },
  maxWidth: "90vw",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: { xs: 2, md: 3 },
  display: "flex",
  flexDirection: "column",
  maxHeight: { xs: "80vh", md: "90vh" },
  "&:focus": {
    outline: "none",
  },
};
